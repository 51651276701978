import { createTheme } from '@mui/material/styles';
import type {} from '@mui/x-date-pickers/themeAugmentation';

/**
 * 
 */
const theme = createTheme({
    palette: {
      mode: 'light',
      background: {
        paper: '#F0F0F0',
      },
      primary: {
        main: '#BCD46C', // Définissez votre couleur primaire
        light: '#D2EDAB'
      },
      secondary: {        
        main: '#FFD25C',//'#F29ABA', // Définissez votre couleur secondaire  // FFC700
        light: '#FEE08E'//'#F1C0D9'
      },
      success: {
        main: '#A8DB5EFF'
      },
      warning: {
        main: '#FFD25C'
      },
     /*  text: {
        //light: '#E4E4E4',
      } */
    },
    typography: {
      fontFamily: 'Outfit, sans-serif', // Police de caractère par défaut
    },
    components: {
      MuiAutocomplete: {
        styleOverrides: {
          noOptions: {
            //display: 'none'
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            display: 'flex',
            alignItems: 'center'
          }
        }
      },
      MuiTooltip: {
        styleOverrides: {
          // Name of the slot
          tooltip: {
            fontSize: 14,
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: '#5f6368',
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          // Name of the slot
          paper: {
            backgroundColor: 'white',
            borderRadius: '18px',
            '&.MuiDialog-paperFullScreen': {
              borderRadius: '0px',
            },
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            marginBottom: '8px'
          }
        }
      },
      MuiCard: {
        styleOverrides: {
          root: {
            backgroundColor: 'white'
          }
        }
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            "&:last-child": {
              "paddingBottom": "8px"
            }
          }
        }
      },
      MuiCardActionArea: {
        styleOverrides: {
          focusHighlight: {
            backgroundColor: 'transparent'
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '32px',
            "&.MuiButton-sizeSmall": {
              "borderRadius": "12px",
            },
          },
        }
      },
    }
  });
  
export default theme;  