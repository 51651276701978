// serviceWorkerRegistration.js

// Fonction pour enregistrer le Service Worker
export const register = () => {
  // Vérifiez si le Service Worker et les Promises sont supportés
  if ('serviceWorker' in navigator && 'Promise' in window) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/serviceWorker.js')
        .then(registration => {
          console.log('Service Worker enregistré avec succès : ', registration.scope);
        })
        .catch(error => {
          console.error('Échec de l’enregistrement du Service Worker : ', error);
        });
    });
  }
};

// Fonction pour désenregistrer le Service Worker
export const unregister = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations()
      .then(registrations => {
        for (let registration of registrations) {
          registration.unregister();
        }
      });
  }
};