import React, { FunctionComponent, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import VehicleService from '../services/VehicleService';
import { Alert, AppBar, Autocomplete, Card, CircularProgress, FormControl, Grid, IconButton, Slide, TextField, 
  Toolbar, Typography, darken, debounce, useMediaQuery, useTheme, Fade } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import BackIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { AppDispatch } from '../store';
import { Garage, MovaDialog } from '@movalib/movalib-commons';
import GarageService from '../services/GarageService';
import GarageSimpleCard from '../components/GarageSimpleCard';
import { setSnackbar } from '../slices/snackbarSlice';
import { useDispatch } from 'react-redux';
import Loader from '../components/Loader';
import { useHistory } from 'react-router-dom';
import GarageDialog from './GarageDialog';
import Logger from '../helpers/Logger';
import GreenLeafImage from "../assets/images/leaf_green_large.png";
import { flexCenter } from '../helpers/Tools';

interface SearchGarageDialogProps {
  open: boolean;
  onClose: () => void;
}

/**
 * Composant permettant de gérer l'effet de transition à l'ouverture de la boite de dialogue
 */
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Fade ref={ref} timeout={200} {...props} />;
});

const SearchGarageDialog: FunctionComponent<SearchGarageDialogProps> = ({ open, onClose }) => {

  const history = useHistory();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [searchedGarage, setSearchedGarage] = useState<string>("");
  const [selectedGarage, setSelectedGarage] = useState<string>("");
  const [garages, setGarages] = useState<Garage[]>();    
  const [message, setMessage] = useState<string>("");    
  const [loading, setLoading] = useState<boolean>(false);  
  const [localOpen, setLocalOpen] = useState<boolean>(open);  
  const [openGarageDialog, setOpenGarageDialog] = useState<boolean>(false);  

/*   useEffect(() => {
    setLocalOpen(open);
  }, [open]); */

  // Déclenchement de la recherche de garagiste, en debounce
/*   useEffect(() => {

    Logger.info(searchedGarage);

    if(searchedGarage.length >= 2){
      debouncedGaragesSearch(searchedGarage, dispatch, setGarages, setSnackbar);
    } else {
      setGarages([]);
    }

  }, [searchedGarage]) */

  /**
  * Déclenche la recherche garages en utilisant "debounce()" qui permet de décaler l'exécution d'une durée exprimée en MS
  */
  const debouncedGaragesSearch = debounce((query: string, dispatch: AppDispatch, setGarages: Function, setSnackbar: Function) => {
      setLoading (true);
      GarageService.searchGarages(dispatch,  query)
        .then(garages => {
            Logger.info(garages);
            setGarages(garages ? garages : []);
        })
        .catch(error => {
            console.error(error);
            dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
        })
        .finally(() => {
          setLoading(false);
        });
      // Durée du debounce en MS
  }, 300);

  const purgeLocalState = () => {
    setMessage("");
    setSearchedGarage("");
    setGarages(undefined);
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> ) => {
    e.preventDefault();
    setSearchedGarage(e.target.value);
    Logger.info(e.target.value);

    if(e.target.value && e.target.value.length >= 2){
      debouncedGaragesSearch(e.target.value, dispatch, setGarages, setSnackbar);
    } else {
      setGarages([]);
    }
  }

  const handleOnClickGarage = (garageId: string) => {
    if(garageId){
      setSelectedGarage(garageId);
      setLocalOpen(false);
      setOpenGarageDialog(true);
    }
  }

  const handleOnClose = () => {
    purgeLocalState();
    onClose();
  }

  const handleOnCloseGarageDialog = () => {
    setOpenGarageDialog(false);
    setLocalOpen(true);
  }

  return (
    <>
      <MovaDialog fullScreen={isMobile} open={localOpen} 
                onClose={handleOnClose} leafImageColor="yellow" 
                title="Rechercher un garage" sx={{ minHeight: 350 }}>
                  
          <TextField 
            fullWidth 
            label="Nom du garage..." 
            autoFocus 
            sx={{ mt:2, minWidth:'200px'}} 
            value={searchedGarage} 
            onChange={e => handleOnChange(e)} />
            
            <Grid container sx={{ minHeight: '400px', flexDirection: 'column'  }}>
                {garages && garages?.map((garage, index) => (
                    <Grid item xs={12} key={index} sx={{ mt: 2 }} >
                        <GarageSimpleCard garage={garage} onClick={handleOnClickGarage} />
                    </Grid>
                ))}

                {garages && garages.length == 0 && searchedGarage && (searchedGarage.length > 1) && !loading &&
                  <Grid item xs={12} sx={{ mt: 2 }} style={flexCenter}>
                    <Card elevation={0} sx={{
                        p:5,
                        width:'100%',
                        color:theme.palette.text.secondary,
                        backgroundColor: theme.palette.grey[100],
                        textAlign:'center'}} >
                      AUCUN RÉSULTAT
                    </Card>
                  </Grid>
                }
            </Grid>
            
            <Loader loading={loading} />

          {message && <Alert severity="error" sx={{ mb: 2 }}>{message}</Alert>}

      </MovaDialog>

      {/* BOITES DE DIALOGUE OUVRABLES DEPUIS CETTE FENETRE */}
      <GarageDialog open={openGarageDialog} garageId={selectedGarage} onClose={handleOnCloseGarageDialog} />
    </>
  );
}

export default SearchGarageDialog;