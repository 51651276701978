import { Garage, MovaDialog, PartsApplicationType, Vehicle, VehicleTire, getApplicationShortLabel, getApplicationsShortLabels } from '@movalib/movalib-commons';
import { useEffect, type CSSProperties, type FC, useState } from 'react';
import { Button, Card, CardContent, Grid, Link, Typography, darken, useMediaQuery, useTheme } from '@mui/material';
import SaveIcon from '@mui/icons-material/Check';
import AppointmentsIcon from '@mui/icons-material/TodayRounded';
import CarIcon from '@mui/icons-material/CarRepairRounded';
import { createDateWithTime, flexCenter, getLastAvailableDate, getLongFormattedDate, mergeMaps } from '../../helpers/Tools';
import GarageService from '../../services/GarageService';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../slices/snackbarSlice';
import MovaAccordion from '../../components/MovaAccordion';
import AppointmentSummaryDialog from './AppointmentSummaryDialog';
import Logger from '../../helpers/Logger';
import { PrestationRequest } from '../../helpers/Types';
import { FLAT_PRESTATION_CODE, TIRE_PRESTATION_CODE } from '../../helpers/Constants';

interface AppointmentSlotsDialogProps {
    open: boolean,
    garage:Garage,
    prestations: PrestationRequest[],
    vehicle?: Vehicle,
    otherReason?: string;
    tireSize?: VehicleTire;
    onClose: () => void;
}

const AppointmentSlotsDialog: FC<AppointmentSlotsDialogProps> = ({ open, garage, prestations, vehicle, otherReason, tireSize, onClose }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch();
    const [localOpen, setLocalOpen] = useState<boolean>(open);
    const [availableSlots, setAvailableSlots] = useState<Map<Date, string[]>>();
    const [expandedAccordion, setExpandedAccordion] = useState<number | null>(0);
    const [selectedAppointmentDate, setSelectedAppointmentDate] = useState<Date>();
    const [openAppointmentSummary, setOpenAppointmentSummary] = useState<boolean>(false);

    const dialogTitleStyle: CSSProperties = {
        color: darken(theme.palette.primary.main, 0.2),
        fontWeight: 700
    }

    useEffect(() => {

        if(open && garage && prestations && vehicle)
            refreshAvailableSlots();

        setLocalOpen(open);

    }, [open]);

    const refreshAvailableSlots = () => {

        if(garage && prestations){
            // Récupération des créneaux disponibles auprès du garage
            GarageService.getGarageAvailableSlots(dispatch, garage.id, prestations.map(p => p.id))
                .then(slots => {

                    if(slots instanceof Map && slots.size > 0){
                        Logger.info(slots)
                        setAvailableSlots(slots);
                    } else {
                        setAvailableSlots(undefined);
                    }

                }).catch(error => {
                    Logger.info(error);
                    dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
                });
        }
    }

    const handleOnClickShowMore = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();

        if(availableSlots){

            // On va chercher plus de créneaux en partant de la dernière date disponible +1
            let lastAvailableDate:Date | null = getLastAvailableDate(availableSlots);

            if(lastAvailableDate){

                let startDate = new Date(lastAvailableDate);
                startDate.setDate(lastAvailableDate.getDate() + 1);

                GarageService.getGarageAvailableSlots(dispatch, garage.id, prestations.map(p => p.id), startDate)
                .then(newSlots => {

                    // Création d'une nouvelle instance de Map pour forcer la mise à jour du rendu
                    let mergedSlots = new Map(availableSlots);
                    mergeMaps(mergedSlots, newSlots);
                    setAvailableSlots(mergedSlots);

                }).catch(error => {
                    Logger.error(error);
                    dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
                });
            }
        }
    }

    const handleOnSelectSlot = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, date:Date, time:string) => {
        e.preventDefault();

        if(date && time){
            setSelectedAppointmentDate(
                createDateWithTime(date, time));

            // On passe à l'écran de confirmation du rendez-vous
            setLocalOpen(false);
            setOpenAppointmentSummary(true);
        }
    }

    const handleOnCloseAppointmentSummary = () => {
        setOpenAppointmentSummary(false);
        setLocalOpen(true);
    }

    const purgeLocalState = () => {
        setAvailableSlots(undefined);
        setSelectedAppointmentDate(undefined);
    }

    const handleOnClose = () => {
        purgeLocalState();
        onClose();
    }

    const handleAccordionChange = (panel: number) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpandedAccordion(isExpanded ? panel : null);
    };

    return (
        <>
         {garage && vehicle &&
            <MovaDialog fullScreen={isMobile} open={localOpen} onClose={handleOnClose} leafImageColor="yellow" title={garage.name} titleStyle={dialogTitleStyle}>
                {/** Rappel infos du RDV */}
                <Card variant='outlined' sx={{
                        backgroundColor: 'transparent',
                        overflow: 'visible', m:2  }}
                    >
                    <CardContent sx={{ pt: 2, pb: 0}}>
                        <Typography variant="subtitle1" component="div" align="center" sx={{ mb:1 }} color="text.primary" >
                            <b>{vehicle.brand} {vehicle.model} {vehicle.version}</b>
                        </Typography>
                        {/** Rappel des prestations sélectionnées */}
                        {garage.prestations && garage.prestations.map((prestation, index) => (
                            <Grid container>
                                {prestations.map(p => p.id).includes(prestation.id) &&
                                    <Grid item xs={12}>
                                        <Typography variant="body1" color="text.primary" sx={{ textAlign: 'center'}}>
                                            {prestation.name}{' '}
                                            {prestations
                                                .filter((p) => p.id === prestation.id)
                                                .map((p) => getApplicationsShortLabels(p.applications))
                                            }
                                        </Typography>
                                        {prestation.code === 'OTHER' &&
                                            <Typography variant="body1" color="text.primary" sx={{ textAlign: 'center'}}>
                                                {otherReason}
                                            </Typography>}
                                    </Grid>
                                }
                            </Grid>
                        ))}
                    </CardContent>
                </Card>

                {/** Choix du créneau */}
                <Typography variant="h6" component="div" color={theme.palette.text.primary} style={flexCenter} sx={{ mt: 3, mb: 2 }}>
                    <AppointmentsIcon sx={{ mr: 1 }} />Choisissez un créneau
                </Typography>
                <Grid container>
                    {availableSlots &&
                        Array.from(availableSlots.keys()).map((date, index) => (
                            <Grid item xs={12} key={index} sx={{ mb: 1, mt: 1}}>
                                <MovaAccordion expanded={expandedAccordion === index}
                                    onChange={handleAccordionChange(index)} title={getLongFormattedDate(date)} content={
                                    availableSlots.get(date)?.map((time, i) => (
                                        <Button key={i} variant="contained" color="primary" onClick={(e) => handleOnSelectSlot(e, date, time)}
                                        sx={{ mb: 1,  mr: 2, backgroundColor: theme.palette.grey[200], boxShadow: 'none', py: 1, px: 1, minWidth: '70px' }}>
                                            {time}
                                        </Button>
                                    ))
                                }  />
                            </Grid>
                        ))
                    }
                    {availableSlots &&
                        <Grid item xs={12} sx={{ mt: 2}}>
                            <Button onClick={(e) => handleOnClickShowMore(e)} variant='outlined' sx={{ color: theme.palette.primary.dark}}>Voir plus</Button>
                        </Grid>
                    }
                </Grid>
            </MovaDialog>
        }

        {garage && prestations && vehicle && selectedAppointmentDate && openAppointmentSummary &&
            <AppointmentSummaryDialog
                open={openAppointmentSummary}
                garage={garage}
                prestations={prestations}
                otherReason={otherReason}
                vehicle={vehicle}
                tireSize={tireSize ? tireSize : vehicle.tireSize}
                appointmentDate={selectedAppointmentDate}
                onClose={handleOnCloseAppointmentSummary} />
        }
        </>
    );
}


export default AppointmentSlotsDialog;
