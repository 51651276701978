import { Button, Card, CardActionArea, CardActions, CardContent, FormControl, FormHelperText, Grid, IconButton, InputLabel, Link, MenuItem, Select, SelectChangeEvent, TextField, Typography, darken, useTheme } from '@mui/material';
import { useState, type FC, useEffect, useRef, Fragment } from 'react';
import CarFigure from "../assets/images/car_figure.png";
import { MovaFormField, Vehicle, Document, validateField, DocumentType, VehicleTire, MovaVehicleTireField, ConfirmationDialog, formatFrenchVehiclePlate } from '@movalib/movalib-commons';
import { formatVehicleTire } from '../helpers/Tools';
import AddIcon from '@mui/icons-material/AddRounded';
import MovaDigitalPassport from './MovaDigitalPassport';
import VehicleService from '../services/VehicleService';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../slices/snackbarSlice';
import Loader from './Loader';
import { DateFormatTypes, formatDateByCountryCode } from '../helpers/DateUtils';
import CancelIcon from '@mui/icons-material/CloseRounded';
import EditIcon from '@mui/icons-material/EditRounded';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { BorderColor } from '@mui/icons-material';
import UserService from '../services/UserService';
import Logger from '../helpers/Logger';

interface VehicleFullCardProps {
    vehicle: Vehicle,
    focused?: boolean,
    onVehicleUpdate?: () => void;
    onVehicleDelete?: () => void;
}

export type MovaVehicleForm = {
    currentMileage: MovaFormField,  // Integer
    averageMileagePerYear: MovaFormField,  // Integer
    tireSize: MovaFormField,
    tireWidth: MovaFormField,
    tireHeight: MovaFormField,
    tireDiameter: MovaFormField,
    tireSpeedIndex: MovaFormField
}

const initialUserFormState = {
    currentMileage: { value: null, isValid: true },
    averageMileagePerYear: { value: null, isValid: true },
    tireSize: { value: null, isValid: true },
    tireWidth: { value: '', isValid: true },
    tireHeight: { value: '', isValid: true },
    tireDiameter: { value: '', isValid: true },
    tireSpeedIndex: { value: '', isValid: true }
}

const VehicleFullCard: FC<VehicleFullCardProps> = ({ vehicle, focused = false, onVehicleUpdate, onVehicleDelete }) => {

    const theme = useTheme();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [openConfirmDocumentDelete, setOpenConfirmDocumentDelete] = useState(false);
    const [openConfirmVehicleDelete, setOpenConfirmVehicleDelete] = useState(false);
    const [documentToDelete, setDocumentToDelete] = useState('');
    // Formulaire utilisé pour les modifications d'informations sur le véhicule
    const [form, setForm] = useState<MovaVehicleForm>(initialUserFormState);
    const [vehicleDocuments, setVehicleDocuments] = useState<Document[]>([]); 
    // Références aux éventuels documents uploadés depuis la fiche
    const invoiceInputRef = useRef(null);
    const tirePictureInputRef = useRef(null);

    useEffect(() => {
        initForm();
      }, [vehicle]);

    const initForm = () => {
        if(vehicle){

            setForm(prevForm => (
                { ...prevForm, ['currentMileage'] : { ...prevForm['currentMileage'], value: vehicle.currentMileage }}));
            setForm(prevForm => (
                { ...prevForm, ['averageMileagePerYear'] : { ...prevForm['averageMileagePerYear'], value: vehicle.averageMileagePerYear }}));
            setForm(prevForm => (
                { ...prevForm, ['tireWidth'] : { ...prevForm['tireWidth'], value: vehicle.tireWidth }}));    
            setForm(prevForm => (
                { ...prevForm, ['tireHeight'] : { ...prevForm['tireHeight'], value: vehicle.tireHeight }}));                
            setForm(prevForm => (
                { ...prevForm, ['tireDiameter'] : { ...prevForm['tireDiameter'], value: vehicle.tireDiameter }}));
            setForm(prevForm => (
                { ...prevForm, ['tireSpeedIndex'] : { ...prevForm['tireSpeedIndex'], value: vehicle.tireSpeedIndex }}));

            if(isVehicleTireSizeDefined(vehicle)){
                setForm(prevForm => (
                    { ...prevForm, ['tireSize'] : { ...prevForm['tireSize'], value: vehicle.tireSize }}));    
            }
                
            Logger.info(form);
        }
    }
    
    useEffect(() => {
        refreshVehicleDocuments();
    }, [vehicle])

    const isVehicleTireSizeDefined = (vehicle:Vehicle) => {
        return vehicle.tireSize && vehicle.tireSize.diameter && vehicle.tireSize.height && vehicle.tireSize.speedIndex && vehicle.tireSize.width;
    }

    const refreshVehicleDocuments = () => {
        if(vehicle){
            setLoading (true);

            // Récupération des documents du véhicule
            VehicleService.getVehicleDocuments(dispatch, vehicle.id)
                .then(documents => {

                    Logger.info(documents); 

                    if(documents && documents.length > 0){
                        setVehicleDocuments(documents);
                    } else {
                        setVehicleDocuments([]);
                    }

                }).catch(error => {
                    Logger.info(error);
                    dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    const validateForm = () => {
        let newForm: MovaVehicleForm = { ...form };

        // Validator pour les champs obligatoires
        newForm.currentMileage = validateField(form.currentMileage, value => !!value, 'Champ obligatoire');
        newForm.averageMileagePerYear = validateField(form.averageMileagePerYear, value => !!value, 'Champ obligatoire');

        // La validation de la saisie des pneumatiques se fait dans le composant "MovaVehicleTireField", traitée dans le callback "handleOnChangeVehicleTire"

        setForm(newForm);

        return newForm.currentMileage.isValid && newForm.averageMileagePerYear.isValid && newForm.tireSize.isValid;
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        handleChange(e.target.name, e.target.value);
    }

    const handleSelectChange = (e: SelectChangeEvent<string>): void => {
        handleChange(e.target.name, e.target.value);
    }  

    const handleChange = (fieldName:string, fieldValue:string): void => {
        const newField: MovaFormField = { [fieldName]: { value: fieldValue, isValid: true } };
    
        setForm({ ...form, ...newField});
    }

    const uploadVehicleDocument = (document: File, documentType:DocumentType) => {

        if(vehicle && document && documentType){
            setLoading (true);

            // Utilisation d'un formData pour permettre le trasnfert de fichier vers l'API
            let formData = new FormData();
            formData.append("documentType", documentType);
            // Ajouter la facture à FormData
            formData.append('file', document);

            // Téléchargement du document
            VehicleService.uploadVehicleDocument(dispatch, vehicle.id, formData)
                .then(response => {

                    // Affichage notification utilisateur
                    dispatch(setSnackbar({ open: true, message: response, severity: 'success' }));

                    // On recharge la liste des documents disponibles
                    refreshVehicleDocuments();

                    // Appel du callback de maj véhicule (car le passport digital peut avoir évolué)
                    if(onVehicleUpdate)
                        onVehicleUpdate();

                }).catch(error => {
                    Logger.info(error);
                    dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    /**
     * 
     * @param event L'upload des documents se fait directement lors du téléchargement
     * @param docType 
     */
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, docType:DocumentType) => {
        event.preventDefault();

        if(event && event.target.files && event.target.files.length > 0 && docType){
            uploadVehicleDocument(event.target.files[0], docType);
        }

    };

    const handleOnClickEdit = () => {

        // On passe la fiche véhicule en mode édition
        setEditMode(true);
    }

    const handleOnChangeVehicleTire = (vehicleTire: VehicleTire, isValid:boolean) => {
        setForm(prevForm => (
            { ...prevForm, ['tireSize'] : { ...prevForm['tireSize'], value: vehicleTire, isValid: isValid }}));   
    }

    const handleOnClickDeleteVehicle = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        setOpenConfirmVehicleDelete(true);
    }

    const handleDeleteDocument = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, documentId: string) => {
        e.preventDefault();
        setDocumentToDelete(documentId);
        setOpenConfirmDocumentDelete(true);
    }

    const handleOnClickValidate = () => {

        if(validateForm()) {
            setLoading (true);
            
            Logger.info(form.tireSize.value);

            let query = {
                currentMileage : form.currentMileage.value,
                averageMileagePerYear : form.averageMileagePerYear.value,
                tireWidth: form.tireSize.isValid && form.tireSize.value ? (form.tireSize.value as VehicleTire).width : undefined,
                tireHeight : form.tireSize.isValid && form.tireSize.value ? (form.tireSize.value as VehicleTire).height : undefined,
                tireDiameter: form.tireSize.isValid && form.tireSize.value ? (form.tireSize.value as VehicleTire).diameter : undefined,
                tireSpeedIndex: form.tireSize.isValid && form.tireSize.value ? (form.tireSize.value as VehicleTire).speedIndex : undefined
            }
            Logger.info(query)
            // Récupération des documents du véhicule
            VehicleService.updateVehicle(dispatch, vehicle.id, query)              
                .then(response => {

                    Logger.info(response); 

                    // Affichage notification utilisateur
                    dispatch(setSnackbar({ open: true, message: response, severity: 'success' }));

                    // Désactivation du mode édition
                    setEditMode(false);

                    // Appel du callback de maj véhicule
                    if(onVehicleUpdate)
                        onVehicleUpdate();

                }).catch(error => {
                    Logger.info(error);
                    dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    const handleOnClickCancel = () => {
        initForm();
        setEditMode(false);
    }

    const handleCloseConfirmDocumentDelete = () => {
        setOpenConfirmDocumentDelete(false);
        setDocumentToDelete('');
    }

    const handleCloseConfirmVehicleDelete = () => {
        setOpenConfirmVehicleDelete(false);
    }

    /**
     * 
     */
    const handleConfirmDocumentDelete = () => {
        setOpenConfirmDocumentDelete(false);

        if(vehicle && documentToDelete){
            setLoading (true);

            // Récupération des documents du véhicule
            VehicleService.deleteVehicleDocument(dispatch, vehicle.id, documentToDelete)              
            .then(response => {

                Logger.info(response); 

                // Affichage notification utilisateur
                dispatch(setSnackbar({ open: true, message: response, severity: 'success' }));

                // Désactivation du mode édition
                setEditMode(false);

                // Appel du callback de maj véhicule
                if(onVehicleUpdate)
                    onVehicleUpdate();

            }).catch(error => {
                Logger.info(error);
                dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
            })
            .finally(() => {
                setLoading(false);
            });
        }
    }

    /**
     * 
     */
    const handleConfirmVehicleDelete = () => {
        setOpenConfirmVehicleDelete(false);

        if(vehicle){
            setLoading (true);

            // Récupération des documents du véhicule
            UserService.deleteUserVehicle(dispatch, vehicle.id)              
            .then(response => {

                Logger.info(response); 

                // Affichage notification utilisateur
                dispatch(setSnackbar({ open: true, message: response, severity: 'success' }));

                // Désactivation du mode édition
                setEditMode(false);

                // Appel du callback de suppression véhicule
                if(onVehicleDelete)
                    onVehicleDelete();

            }).catch(error => {
                Logger.info(error);
                dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
            })
            .finally(() => {
                setLoading(false);
            });
        }
    }
    
    return (
        <>
           { vehicle && 
                    <Card variant='outlined' sx={{ 
                        backgroundColor: focused ? theme.palette.primary.light : 'white', 
                        overflow: 'visible', mt: 4, pb: 1 }}
                    >
                        <img src={CarFigure} style={{
                            position: 'relative',
                            width: '40%',
                            top: '-25px',
                            left: '-15px',
                            zIndex: 200}} alt='Icone Voiture'>
                        </img>

                        <MovaDigitalPassport digitalPassportIndex={vehicle.digitalPassportIndex} />

                        <CardContent sx={{ pt: 0, pb: 0}}>
                            <Typography variant="h6" component="div" align="center" sx={{ mb:1 }} color={darken(theme.palette.primary.main, 0.2)}>
                                {vehicle.brand && `${vehicle.brand} `}
                                {vehicle.model && `${vehicle.model} `}
                                {vehicle.version && `${vehicle.version}`}
                            </Typography>
                            <Grid container justifyContent="space-between">

                                <Grid item xs={12}>
                                    <Typography variant="body1" color="text.primary">
                                        <b>{formatFrenchVehiclePlate(vehicle.plate)}</b>
                                    </Typography>
                                </Grid>     

                                {!editMode && <Grid container textAlign='justify' sx={{ pt: 2 }}>
                                    <Grid item xs={8} >                                    
                                        <Typography variant="body1" color="text.secondary">
                                            Km actuel :
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} sx={{ textAlign: 'right' }}>                                    
                                        <Typography variant="body1" color="text.secondary">
                                            <b>{vehicle.currentMileage} km</b>
                                        </Typography>
                                    </Grid>
                                </Grid> }

                                {editMode && <Grid item xs={12}>     
                                    <TextField
                                        label="Kilométrage actuel"
                                        name="currentMileage"
                                        variant="outlined"
                                        type="number" 
                                        required
                                        value={form.currentMileage.value}
                                        onChange={(e) => handleInputChange(e)}
                                        error={(Boolean(form.currentMileage.error))}
                                        sx={{ 
                                            width: '100%', 
                                            mt: 2,
                                            '& input': { textTransform: 'uppercase' } // CSS pour forcer les majuscules dans l'input
                                        }}
                                        helperText={Boolean(form.currentMileage.error && form.currentMileage.value > 0)
                                            ? form.currentMileage.error : "Sur votre tableau de bord 😉"}
                                        />
                                </Grid> }
                                
                                {!editMode && <Grid container textAlign='justify' sx={{ pt: 2 }}>
                                    <Grid item xs={8} >                                    
                                        <Typography variant="body1" color="text.secondary">
                                            Km moyen annuel :
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} sx={{ textAlign: 'right' }}>                                    
                                        <Typography variant="body1" color="text.secondary">
                                            <b>{vehicle.averageMileagePerYear} km</b>
                                        </Typography>
                                    </Grid>
                                </Grid> }

                                {editMode && <Grid item xs={12}>                                    
                                    <FormControl fullWidth margin="normal" error={Boolean(form.averageMileagePerYear.error)}>
                                        <InputLabel id="averageMileagePerYear-label">Kilométrage moyen annuel</InputLabel>
                                        <Select
                                            labelId="averageMileagePerYear-label"
                                            id="averageMileagePerYear"
                                            name="averageMileagePerYear"
                                            value={form.averageMileagePerYear.value ? 
                                                String(form.averageMileagePerYear.value) : ''}
                                            onChange={e => handleSelectChange(e)}
                                            label="Kilométrage moyen annuel"
                                        >
                                            <MenuItem value={5000}>5 000</MenuItem>
                                            <MenuItem value={10000}>10 000</MenuItem>
                                            <MenuItem value={15000}>15 000</MenuItem>
                                            <MenuItem value={20000}>20 000</MenuItem>
                                            <MenuItem value={25000}>25 000</MenuItem>
                                            <MenuItem value={30000}>30 000</MenuItem> 
                                            <MenuItem value={50000}>50 000</MenuItem>            
                                            <MenuItem value={75000}>75 000</MenuItem>
                                            <MenuItem value={100000}>100 000</MenuItem>
                                            <MenuItem value={999999}>+100 000</MenuItem>   
                                        </Select>
                                        <FormHelperText>{form.averageMileagePerYear.error}</FormHelperText>
                                    </FormControl>
                                </Grid>}

                                 {!editMode && <Grid container textAlign='justify' sx={{ pt: 2 }}>
                                    <Grid item xs={6}>                                    
                                        <Typography variant="body1" color="text.secondary">
                                            Pneumatiques :
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} sx={{ textAlign: 'right' }}>                                    
                                        <Typography variant="body1" color="text.secondary">
                                            {isVehicleTireSizeDefined(vehicle) ? <b>{formatVehicleTire(vehicle.tireSize)}</b> : '-' }
                                        </Typography>
                                    </Grid>
                                </Grid> }

                                {editMode && <Grid item xs={12} sx={{ mt: 1 }}>     
                                    <MovaVehicleTireField
                                        vehicleTire={form.tireSize.value}
                                        onChangeVehicleTire={handleOnChangeVehicleTire}
                                        />
                                </Grid> }

                            </Grid>

                            {!editMode &&  <>

                                <Grid item xs={12}>
                                    <Typography variant="h6" component="div" align="center" sx={{ mt: 3, mb:1 }} color={darken(theme.palette.primary.main, 0.2)}>
                                        CARNET DU VÉHICULE
                                    </Typography>
                                </Grid>  

                                {/** Les FACTURES du véhicule */}
                                {vehicleDocuments && vehicleDocuments?.filter(doc => doc.type === DocumentType.VEHICLE_MAINTENANCE_INVOICE)
                                .map((invoice, index) => (
                                    <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }} key={index+1}>
                                        <Grid item xs={11}  key={(index+1)*50} sx={{ textAlign: 'left' }} >
                                            <Link color={darken('#F29ABA', 0.2)} href={invoice.fileSignedUrl} target="_blank" rel="noopener">
                                                Facture du <>{formatDateByCountryCode(invoice.creationDate, 'fr', DateFormatTypes.SHORT_FORMAT_DATE)}</>
                                            </Link>
                                        </Grid>
                                        <Grid item xs={1} key={(index+1)*100} sx={{ textAlign: 'right' }}>
                                            <IconButton onClick={(e) => handleDeleteDocument(e, invoice.id)} >
                                                <CloseIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                ))}
    
                                {/** Les PHOTOS du véhicule */}
                                {vehicleDocuments && vehicleDocuments?.filter(doc => doc.type === DocumentType.VEHICLE_TIRE_PHOTO)
                                .map((tirePhoto, index) => (
                                    <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }} key={index+1}>
                                        <Grid item xs={11} sm={6} md={4} key={(index+1)*50} sx={{ textAlign: 'left' }} >
                                            <Link color={darken('#F29ABA', 0.2)} href={tirePhoto.fileSignedUrl} target="_blank" rel="noopener">
                                                Photo pneu du <>{formatDateByCountryCode(tirePhoto.creationDate, 'fr', DateFormatTypes.SHORT_FORMAT_DATE)}</>
                                            </Link>
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={1} key={(index+1)*100} sx={{ textAlign: 'right' }}>
                                            <IconButton onClick={(e) => handleDeleteDocument(e, tirePhoto.id)}>
                                                <CloseIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                ))}

                                <Grid container >
                                    <Grid item xs={6} sx={{ mt: 2 }} >
                                        {/* Input caché de type "file" */}
                                        <div>
                                            <input
                                                accept="image/*, application/pdf"
                                                type="file"
                                                style={{ display: 'none' }}
                                                ref={invoiceInputRef}
                                                id="raised-button-invoice"
                                                onChange={(e) => handleFileChange(e, DocumentType.VEHICLE_MAINTENANCE_INVOICE)}
                                            />
                                            <label htmlFor="raised-button-invoice">
                                                <Button size='large' component="span" variant="outlined" 
                                                    sx={{ alignItems: 'center', width:'90%', mt: 2, mb: 1, height: '70px', p:1, 
                                                        color:darken(theme.palette.primary.main, 0.2) }}>
                                                    Ajouter Facture
                                                </Button>
                                            </label>  
                                        </div>                              
                                    </Grid>

                                    <Grid item xs={6}  sx={{ mt: 2 }} >
                                        {/* Input caché de type "file" */}
                                        <div>
                                            <input
                                                accept="image/*"
                                                type="file"
                                                style={{ display: 'none' }}
                                                ref={tirePictureInputRef}
                                                id="raised-button-tire"
                                                onChange={(e) => handleFileChange(e, DocumentType.VEHICLE_TIRE_PHOTO)}
                                            />
                                            <label htmlFor="raised-button-tire">
                                                <Button component="span" size='large' variant="outlined" 
                                                    sx={{ alignItems: 'center', width:'90%', mt: 2, mb: 1, height: '70px', p:1, 
                                                        color:darken(theme.palette.primary.main, 0.2)  }}>
                                                    Ajouter Photo Pneu
                                                </Button>
                                            </label>    
                                        </div>                              
                                    </Grid>
                                </Grid>

                                </>
                            }

                        </CardContent>

                        <Loader loading={loading} /> 

                        <CardActions sx={{ mt: 3, justifyContent: editMode ? 'center' : 'end' }}>
                            {!editMode && 
                            <>
                                <Button onClick={handleOnClickEdit} color="inherit" sx={{ width: '45%' }} variant='text'>
                                    <EditIcon sx={{ mr: 1 }} />MODIFIER
                                </Button>
                            </>
                            }

                            {editMode &&
                            <>
                                <Button onClick={handleOnClickCancel} sx={{ width: '45%', color:theme.palette.text.secondary }} variant='text'>
                                    <CancelIcon sx={{ mr: 1 }} />ANNULER
                                </Button>

                                <Button onClick={handleOnClickValidate} sx={{ width: '45%', color: darken(theme.palette.primary.main, 0.2) }} variant='text'>
                                    <EditIcon sx={{ mr: 1 }} />VALIDER
                                </Button>
                            </>
                            }
                        </CardActions>
                        
                    </Card>
            } 

            {editMode &&
                <Button onClick={(e) => handleOnClickDeleteVehicle(e)} sx={{ width: '90', mt: 4, color:theme.palette.error.light,
                 borderColor: theme.palette.error.light }}  variant='outlined'>
                    Supprimer le véhicule
                </Button>
            }

            <ConfirmationDialog 
                open={openConfirmDocumentDelete}
                onClose={handleCloseConfirmDocumentDelete}
                onConfirm={handleConfirmDocumentDelete}
                message="Êtes-vous sûr de vouloir supprimer ce document ?"
            />

            <ConfirmationDialog 
                open={openConfirmVehicleDelete}
                onClose={handleCloseConfirmVehicleDelete}
                onConfirm={handleConfirmVehicleDelete}
                message="Êtes-vous sûr de vouloir supprimer ce véhicule ?"
            />
        </>
    );
}

export default VehicleFullCard;
