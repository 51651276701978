import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { request } from "../helpers/ApiHelper";
import { API_BASE_URL } from "../helpers/Constants";
import { APIMethod } from "../helpers/Enums";
import { User, Vehicle, Event } from "@movalib/movalib-commons";

export default class UserService {

    static rejectAppointment(dispatch: Dispatch<AnyAction>, appointmentId: string): Promise<string> {
        return request({
          url: `${API_BASE_URL}/user/documents/${appointmentId}/reject`,
          method: APIMethod.PATCH
        }, dispatch);
    }

    static scheduleAppointment(dispatch: Dispatch<AnyAction>, appointmentId: string): Promise<string> {
        return request({
          url: `${API_BASE_URL}/user/documents/${appointmentId}/schedule`,
          method: APIMethod.PATCH
        }, dispatch);
    }

    static rejectUserDocument(dispatch: Dispatch<AnyAction>, documentId: string, reason: string): Promise<string> {
        return request({
          url: `${API_BASE_URL}/user/documents/${documentId}/reject`,
          method: APIMethod.POST,
          body: reason
        }, dispatch);
    }

    static approveUserDocument(dispatch: Dispatch<AnyAction>, documentId: string): Promise<string> {
        return request({
          url: `${API_BASE_URL}/user/documents/${documentId}/approve`,
          method: APIMethod.PATCH
        }, dispatch);
    }

    static getUserAppointment(dispatch: Dispatch<AnyAction>, eventId: string): Promise<Event> {
        return request({
            url: `${API_BASE_URL}/user/appointments/${eventId}`,
            method: APIMethod.GET,
        }, dispatch);
    }

    static updateUserProfile(req: any, dispatch?: Dispatch<AnyAction>): Promise<string> {
        return request({
          url: `${API_BASE_URL}/user`,
          method: APIMethod.PATCH,
          body: JSON.stringify(req)
        }, dispatch);
    }

    static deleteUserVehicle(dispatch: Dispatch<AnyAction>, vehicleId: number): Promise<string> {
        return request({
          url: `${API_BASE_URL}/user/vehicle/${vehicleId}`,
          method: APIMethod.DELETE
        }, dispatch);
    }

    static cancelUserEvent(dispatch: Dispatch<AnyAction>, eventId: string): Promise<string> {
        return request({
          url: `${API_BASE_URL}/user/event/${eventId}/cancel`,
          method: APIMethod.PATCH
        }, dispatch);
      }

    static getActiveUserAppointments(dispatch: Dispatch<AnyAction>): Promise<Event[]> {
        return request({
            url: `${API_BASE_URL}/user/appointments/active`,
            method: APIMethod.GET,
        }, dispatch);
    }

    static getPastUserAppointments(dispatch: Dispatch<AnyAction>): Promise<Event[]> {
        return request({
            url: `${API_BASE_URL}/user/appointments/past`,
            method: APIMethod.GET,
        }, dispatch);
    }

    static addUserVehicle(dispatch: Dispatch<AnyAction>, req: any): Promise<string> {
        return request({
          url: `${API_BASE_URL}/user/vehicles`,
          method: APIMethod.POST,
          body: JSON.stringify(req)
        }, dispatch);
    }

    static getUserVehicles(dispatch: Dispatch<AnyAction>): Promise<Vehicle[]> {
        return request({
            url: `${API_BASE_URL}/user/vehicles`,
            method: APIMethod.GET,
        }, dispatch);
    }

    static validateAccount(req: any): Promise<string> {
        return request({
            url: `${API_BASE_URL}/user/validate-account`,
            method: APIMethod.POST,
            body: JSON.stringify(req)
        });
    }

    static signUp(req: any): Promise<string> {
        return request({
            url: `${API_BASE_URL}/users`,
            method: APIMethod.POST,
            body: JSON.stringify(req)
        });
    }

    /**
     * TODO : côté API n'envoyer qu'un seul User et non un tableau pour éviter d'avoir à tester
     * @param email 
     * @param password 
     * @returns 
     */
    static async getCurrentUser(dispatch?: Dispatch<AnyAction>): Promise<User | undefined> {
        try {
            return await request({
                url: `${API_BASE_URL}/user/me`,
                method: APIMethod.GET
            }, dispatch);
        } catch (error) {
            console.error('Error occurred during getting user:', error);
            return Promise.reject(error);
        }
    }
    
}