import { Container, CssBaseline } from '@mui/material';
import React, { Fragment, FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import PinkLeafImage from "../assets/images/leaf_pink_large.png";
  
const PageNotFound: FunctionComponent = () => {
  
  return (
    <Fragment>
      <Container sx= {{
        textAlign: 'center',
        mt: 12
      }}>
          <img src={PinkLeafImage} style={{
                position: 'fixed', 
                float:'left',
                width: '150px',
                height: '350px',
                top: '-20%',
                zIndex: -10,
                left: 0,
                opacity: '0.3'}} alt='Feuille Movalib' />
          <CssBaseline />
          <h1>Hey, cette page n'existe pas !</h1> 
          <Link to="/home">
            Retour à l'accueil
          </Link>
      </Container>
    </Fragment>
  );
}
  
export default PageNotFound;