import { format, utcToZonedTime } from 'date-fns-tz';
import { fr } from 'date-fns/locale';

export enum DateFormatTypes {
    SHORT_FORMAT_DATE = 'dd/MM/yyyy',
    LONG_FORMAT_DATETIME = 'dd/MM/yyyy à HH:mm',
    LONG_FORMAT_DATETIME_LITERAL = "EEEE dd MMMM yyyy 'à' HH:mm"
}

export const formatDateByCountryCode = (date: Date | undefined, countryCode: string, formatType: DateFormatTypes): string => {

    if(date){
        // Tableau de correspondance entre les codes de pays et les fuseaux horaires
        const countryTimeZones: { [key: string]: string } = {
            'FR': 'Europe/Paris',
            // Ajoutez d'autres correspondances de codes de pays et de fuseaux horaires ici
        };

        const timeZone = countryTimeZones[countryCode.toUpperCase()];

        if (!timeZone) {
            throw new Error('Code de pays non pris en charge');
        }

        // Convertir la date UTC en date du fuseau horaire local
        const zonedDate = utcToZonedTime(date, timeZone);

        // Formater la date
        switch(formatType){

            case DateFormatTypes.SHORT_FORMAT_DATE:
            case DateFormatTypes.LONG_FORMAT_DATETIME:
                return format(zonedDate, formatType, { timeZone, locale: fr });

            case DateFormatTypes.LONG_FORMAT_DATETIME_LITERAL:
                return getLongFormattedDateTime(zonedDate, timeZone, fr);
        }

        return format(zonedDate, formatType, { timeZone, locale: fr });
    }

    return '';
};

export function getLongFormattedDateTime(date: Date | undefined, timeZone: string, locale: Locale){
    if(date){
      const day = capitalizeFirstLetter(format(date, 'eeee', { timeZone, locale: locale }));
      const month = capitalizeFirstLetter(format(date, 'MMMM', { timeZone, locale: locale }));
      const hours = format(date, 'HH', { timeZone, locale: locale });
      const minutes = format(date, 'mm', { timeZone, locale: locale });
      const dayOfMonth = format(date, 'dd', { timeZone, locale });
  
      return `${day} ${dayOfMonth} ${month} à ${hours}:${minutes}`;
    }
    return '';
}

export function capitalizeFirstLetter(str: string): string {
    if (str.length === 0) {
        return str;
    }

    const firstChar = str.charAt(0).toUpperCase();
    const restOfString = str.slice(1);

    return firstChar + restOfString;
}
