import { GenderSelector, MovaDialog, MovaFormField, User, validateEmail, validateField, validatePhoneNumber,
    capitalizeFirstLetter } from '@movalib/movalib-commons';
import { Alert, Box, Button, SelectChangeEvent, TextField, useMediaQuery, useTheme } from '@mui/material';
import { useState, type FC, useEffect } from 'react';
import UserService from '../services/UserService';
import { useDispatch } from 'react-redux';
import Logger from '../helpers/Logger';
import { setSnackbar } from '../slices/snackbarSlice';
import ConfirmIcon from '@mui/icons-material/Check';
import Loader from '../components/Loader';
import { setUserData } from '../slices/userSlice';

interface SettingsDialogProps {
    open:boolean
    onClose: () => void;
}

type UserSettingsForm = {
    // TODO
}

const initialFormState = {
    //TODO
    email: { value: '', error: '', isValid: true },
    firstname: { value: '', error: '', isValid: true },
    lastname: { value: '', error: '', isValid: true },
    gender: { value: '', error: '', isValid: true },
    birthDate: { value: '', error: '', isValid: true },
    phoneNumber: { value: '', error: '', isValid: true }
};

const SettingsDialog: FC<SettingsDialogProps> = ({ open, onClose }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch();
    const [form, setForm] = useState<UserSettingsForm>(initialFormState);
    const [user, setUser] = useState<User | undefined>();
    const [loading, setLoading] = useState<boolean>(false); 
    const [localOpen, setLocalOpen] = useState<boolean>(open); 
    const [message, setMessage] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');

    useEffect(() => {

        purgeLocalState();
        refreshUser();
        setLocalOpen(open);
        
    }, [open]);

    useEffect(() => {

        if(user){
 /*            setForm(prevForm => (
                { ...prevForm, ['email'] : { ...prevForm['email'], value: user.email }}));
            setForm(prevForm => (
                { ...prevForm, ['firstname'] : { ...prevForm['firstname'], value: user.firstname }}));
            setForm(prevForm => (
                { ...prevForm, ['lastname'] : { ...prevForm['lastname'], value: user.lastname }}));    
            setForm(prevForm => (
                { ...prevForm, ['gender'] : { ...prevForm['gender'], value: user.gender ?? ''}}));                
            setForm(prevForm => (
                { ...prevForm, ['birthDate'] : { ...prevForm['birthDate'], value: user.birthDate ?? '' }}));
            setForm(prevForm => (
                { ...prevForm, ['phoneNumber'] : { ...prevForm['phoneNumber'], value: user.phoneNumber ?? '' }})); */
        }
        
    }, [user])

    const refreshUser = () => {
        setLoading(true);

        // Récupération des véhicules de l'utilisateur courant
        UserService.getCurrentUser(dispatch)
            .then(user => {

                if(user){
                    // Actualisation en local et dans le localStorage
                    setUser(user);
                    dispatch(setUserData(user));
                }

            }).catch(error => {
                Logger.error(error);
                dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const purgeLocalState = () => {
        setMessage('');
        setErrorMessage('');
        setUser(undefined);
    }
    
    const handleOnClose = () => {
        purgeLocalState();
        onClose();
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        handleChange(e.target.name, e.target.value);
    }

    const handleSelectChange = (e: SelectChangeEvent<string>): void => {
        handleChange(e.target.name, e.target.value);
    }   

    const handleChange = (name: string, value: string) => {
        const fieldName: string = name;
        let fieldValue: string = value;

        // Capitalisation automatique du prénom
        if (fieldName == "firstname"){
            fieldValue = capitalizeFirstLetter(fieldValue);
        }

        // Uppercase automatique pour le nom
        if (fieldName == "lastname"){
            fieldValue = fieldValue.toUpperCase();
        }

        const newField: MovaFormField = { [fieldName]: { value: fieldValue, isValid: true } };

        setForm({ ...form, ...newField});
    }

    const validateForm = () => {

        let newForm: UserSettingsForm = { ...form };

        // TODO : Validators
        setForm(newForm);

        return false;
    }

    const handleSubmit = () => {

        if(validateForm()){

            setLoading(true);

            // Création de la requête
            let req = {
                //TODO
            }
    
   /*          UserService.updateUserSettings(req)
                .then((response) => {
            
                    purgeLocalState();
            
                    // Affichage notification utilisateur
                    setMessage(response);
                    //dispatch(setSnackbar({ open: true, message: response, severity: 'success' }));
            
                    // Actualisation de l'utilisateur
                    refreshUser();
            
                }).catch(error => {
                    Logger.error(error);
                    setErrorMessage(error);
                })
                .finally(() => {
                    setLoading(false);
                }); */
        }
    }

    const isSubmitAuthorized = () => {

        let changeDetected: boolean = true;

        if(form && user){            
             // On active le bouton de soumission si au moins une des valeurs a été modifiée
      /*       changeDetected = (user?.firstname !== form.firstname.value)
                || (user?.lastname !== form.lastname.value)
                || (user?.email !== form.email.value)
                || ((user?.gender ?? '') !== form.gender.value)
                || ((user?.birthDate ?? '') !== form.birthDate.value)
                || ((user.phoneNumber ?? '') !== form.phoneNumber.value); */
        }

        return !changeDetected;
    }

    return (
        <>
        {user && 
            <MovaDialog 
                fullScreen={isMobile} 
                open={localOpen} 
                onClose={handleOnClose} 
                leafImageColor="pink" 
                title="PRÉFÉRENCES"
            >
                <Loader loading={loading} />

                <Alert severity='info' sx={{ mt: 2 }} variant='outlined'>C'est pour bientôt !! 💪😉 <br/><br/>
                Pour toute demande relative à votre compte vous pouvez nous contacter à : <b>contact@movalib.com</b></Alert>
                
            </MovaDialog>
        }
        </>
    );
}

export default SettingsDialog;
