import { Garage } from "@movalib/movalib-commons";
import { Card, CardActionArea, CardContent, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { flexCenter } from "../helpers/Tools";


interface GarageSimpleCardProps {
    garage: Garage;
    showBorder?:boolean,
    onClick?: (garageId:string) => void;
}

const GarageSimpleCard: React.FunctionComponent<GarageSimpleCardProps> = ({ garage, showBorder = true, onClick }) => {

    const theme = useTheme();

    const handleOnClick = () => {
        if(garage && onClick)
            onClick(garage.id);
    }
    
    return (
    <>
        {garage && 
        <CardActionArea onClick={handleOnClick} sx={{ width:'100%'}}>
            <Card variant='outlined'
                sx={{ backgroundColor: 'transparent', border: showBorder ? '1px solid rgba(0, 0, 0, 0.12)' : 'none' }}>
                <CardContent>
                    <Typography gutterBottom variant="h6" component="div" color={theme.palette.primary.main}>
                        <b>{garage.name}</b>
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        {garage.address && (
                            <>
                                {garage.address.streetName && <Typography variant="body1">{garage.address.streetName}</Typography>} 
                                {garage.address.additional && <Typography variant="body1">{garage.address.additional}</Typography>}
                                {garage.address.postalCode && garage.address.cityName && <Typography variant="body1">
                                    {garage.address.postalCode}&nbsp;&nbsp;{garage.address.cityName}
                                    </Typography>}
                            </>
                        )}
                    </Typography>
                </CardContent>
            </Card>
        </CardActionArea> }
    </>
    );

};

export default GarageSimpleCard;