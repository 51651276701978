import React, { Component } from 'react';

export function ErrorHandler(WrappedComponent: React.ComponentType<any>) {
  return class extends Component {
    
    componentDidCatch(error: Error, info: React.ErrorInfo) {

      console.error('Une erreur est survenue :', error, info);

      // Purger le localStorage
      localStorage.clear();
      
      // Rechargement de la page pour réinitialiser l'état de l'application
      //window.location.reload();
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
}
