import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import type { FC } from 'react';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface MovaAccordionProps {
    title: string,
    content: React.ReactNode,
    onChange?: ((event: React.SyntheticEvent<Element, Event>, expanded: boolean) => void),
    elevation?: number,
    expanded?: boolean,
    disabled?: boolean
}

const MovaAccordion: FC<MovaAccordionProps> = ({ title, content, disabled = false, onChange, elevation = 1, expanded = false }) => {
    return (
        <>
         <Accordion defaultExpanded={expanded} elevation={elevation} disabled={disabled} sx={{ backgroundColor: 'white' }}
            onChange={onChange}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="accordion-content"
                id="accordion-header"
            >
                <Typography>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ textAlign:'left' }}>
                {content}
            </AccordionDetails>
        </Accordion>
        </>
    );
}

export default MovaAccordion;
