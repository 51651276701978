import { Alert, Card, CardActionArea, CardContent, Grid, Typography, useTheme } from '@mui/material';
import type { CSSProperties, FC } from 'react';
import CarFigure from "../assets/images/car_figure.png";
import { Event, EventState, Vehicle } from '@movalib/movalib-commons';
import { flexCenter, getAlertSeverity, getEventStateColor, getEventStateLabel, getLongFormattedDateTime, getRotatedIconStyle } from '../helpers/Tools';
import AppointmentDoneIcon from '@mui/icons-material/EventAvailableRounded';
import { DateFormatTypes, formatDateByCountryCode } from '../helpers/DateUtils';
import AppointmentScheduledIcon from '@mui/icons-material/EventNoteRounded';
import AppointmentNewIcon from '@mui/icons-material/EventRounded';
import AppointmentCancelledIcon from '@mui/icons-material/EventBusyRounded';
import AppointmentPassedIcon from '@mui/icons-material/EventRepeat';

interface AppointmentSimpleCardProps {
    appointment:Event,
    focused?:boolean,
    onCardClick?: (appointment: Event) => void;
}

const AppointmentSimpleCard: FC<AppointmentSimpleCardProps> = ({ appointment, focused = false, onCardClick }) => {

    const theme = useTheme();

    const handleOnCardClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        
        if(onCardClick)
            onCardClick(appointment);
    }

    const getRotatedIconStyle: CSSProperties = {
          position: 'relative',
          top: '-25px',
          zIndex: 200,
          left: '-50%',
          height: '40px',
          width: '40px',
          transform: 'rotate(-20deg)',
          color: getEventStateColor(appointment.state)
    }

    const getAppointmentIcon = () => {
        if(appointment){

            // Pour un événement passé, peu importe l'état
            if(appointment.start && new Date(appointment.start) < new Date()){
                return <AppointmentPassedIcon style={getRotatedIconStyle} />;   
            }

            switch(appointment.state){
                case EventState. NEW:
                    return <AppointmentNewIcon style={getRotatedIconStyle} />;
                case EventState.ACCEPTED:
                case EventState.REJECTED:
                case EventState.COMPLETED:
                case EventState.SCHEDULED:
                    return <AppointmentScheduledIcon style={getRotatedIconStyle} />;
                case EventState.DONE:
                    return <AppointmentDoneIcon style={getRotatedIconStyle} />;   
                case EventState.CANCELLED:
                    return <AppointmentCancelledIcon style={getRotatedIconStyle} />;   
            }
        }
    }

    return (
        <>
           {appointment && 
                <CardActionArea onClick={(e) => handleOnCardClick(e)}>
                    <Card variant='outlined' sx={{ overflow: 'visible',
                        backgroundColor: focused ? theme.palette.primary.light : 'white', borderColor: getEventStateColor(appointment.state) }}
                    >
                        <CardContent sx={{ pt: 0, pb: 0}}>
                            <Typography variant="h6" component="div" sx={{ mt: 1, mb:1, fontSize: '18px' }} style={flexCenter}>
                                {getAppointmentIcon()}
                                <span style={{ position:'absolute' }}>
                                    <b>{formatDateByCountryCode(appointment.vehicleDepositDate ?? appointment.start, 'fr', DateFormatTypes.LONG_FORMAT_DATETIME_LITERAL)}</b>
                                </span>
                            </Typography>
                            <Grid container justifyContent="center">
                                <Typography variant="subtitle1" color="text.secondary">
                                    <b>{appointment.garageName.toUpperCase()}</b>
                                </Typography>
                                {/** Rappel des prestations sélectionnées */}
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography variant="body1" color="text.primary" sx={{ textAlign: 'center'}}>
                                            {appointment.title}
                                            {appointment.prestations?.map(p => p.code).includes('OTHER') && <><br/>{appointment.notes}</>}
                                        </Typography>
                                            
                                        <Typography variant="subtitle2" color="text.primary" sx={{ textAlign: 'center', mt: 1}}>
                                        {appointment.vehicle ? 
                                            <b>{appointment.vehicle.brand} {appointment.vehicle.model} {appointment.vehicle.version}</b>
                                            : 'Véhicule inconnu'}   
                                        </Typography>

                                    </Grid>
                                </Grid>
                                {/** Rappel statut du rendez-vous */}
                                <Alert style={flexCenter}
                                        sx={{ mt: 2, mb: 1, px: 2, py: 0, width: '100%', backgroundColor:getEventStateColor(appointment.state) }} 
                                        severity={ getAlertSeverity(appointment.state) } variant='filled'>
                                            {getEventStateLabel(appointment.state)}</Alert> 

                            </Grid>
                        </CardContent>
                    </Card>
                </CardActionArea>
            }
        </>
    );
}

export default AppointmentSimpleCard;
