import { Card, CardContent, Typography, useMediaQuery, useTheme } from '@mui/material';
import type { FC } from 'react';
import InfoIcon from '@mui/icons-material/InfoRounded';
import { getRotatedIconStyle } from '../helpers/Tools';

interface EmptyCardProps {
    message?: string
}   

const EmptyCard: FC<EmptyCardProps> = ({ message = "Aucun résultat" }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            <Card variant='outlined' sx={{ overflow: 'visible', mt: 2,  mx: isMobile ? 0 : 2 }}>
                <InfoIcon style={getRotatedIconStyle()} sx={{ color: theme.palette.grey[300] }} />
                <CardContent sx={{ pt: 0, pb: 0}}>
                    <Typography variant="subtitle1" component="div" align="center" 
                        sx={{ mt: '-15px', color: theme.palette.grey[400]  }}>
                        {message}
                    </Typography>
                </CardContent>
            </Card>    
        </>
    );
}

export default EmptyCard;
