import React, { FunctionComponent, useEffect, useState } from 'react';
import { HashRouter as Router, Switch, Route, useLocation, useHistory, Redirect } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { store, persistor, RootState } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import PrivateRoute from './PrivateRoute';
import PageNotFound from './pages/PageNotFound';
import theme from './theme'; // Import du thème personnalisé
import './App.css';
import { fr } from "date-fns/locale";
import { PrivateRoutesEnum } from './helpers/Enums';
import MovaSnackbar from './components/MovaSnackbar';
import MovaAppBar from './components/MovaAppBar';
import Login from './pages/Login';
import Home, { TabItems } from './pages/Home';
import SignUp from './pages/SignUp';
import ProfileDialog from './dialog/ProfileDialog';
import SettingsDialog from './dialog/SettingsDialog';
import Garage from './pages/Garage';
import { deleteCookie, readCookie } from './helpers/Tools';
import { PALETTE_THIRD_COLOR_MAIN } from './helpers/Constants';
import AuthenticationService from './services/AuthenticationService';
import {  AccountValidation, Logger, MovaAppType } from '@movalib/movalib-commons';
import { setSnackbar } from './slices/snackbarSlice';
import { Box, Typography } from '@mui/material';
import { COOKIE_INDIVIDUAL_TOKEN } from '@movalib/movalib-commons/dist/src/helpers/CookieUtils';

// FC : Function Component (type du composant App), recommandé par React car plus performant
const App: FunctionComponent = () => {
  return (
      <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
                  <ThemeProvider theme={theme}>
                      <Router>
                          <AppContent />
                          <MovaSnackbar />
                      </Router>
                  </ThemeProvider>
              </LocalizationProvider>
          </PersistGate>
      </Provider>
  );
};

const AppContent: FunctionComponent = () => {
    
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();

    //const userFromState = useSelector((state: RootState) => state.reducer.garage.garage); // Récupération du user depuis le state Redux

    //const [currentUser, setCurrentUser] = useState<User | null>(null);
    const [currentRoute, setCurrentRoute] = useState<string>("");

    useEffect(() => {

        // Mettre à jour le user actuel
        //setCurrentGarage(garageFromState);

        // Mettre à jour la route actuelle
        setCurrentRoute(location.pathname);

        // Si la page demandée est celle du login
        if(location.pathname && location.pathname == "/login"){
            // Et qu'un token est disponible dans les cookies, on vérifie le cookie
            let token = readCookie(COOKIE_INDIVIDUAL_TOKEN);
            if(token){
                AuthenticationService.validateToken(token)
                    .then((response) => {
                        // Token valide, on redirige l'utilisateur vers l'accueil
                        history.push('/home');
                    })
                    .catch(error => {
                        // Si l'appel n'aboutie pas en 200 (token valide), on supprime le token et on prévient l'utilisateur
                        Logger.info(error);
                        deleteCookie(COOKIE_INDIVIDUAL_TOKEN);
                        dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
                    });
            }
        }

        return () => {
            /* EQ : componentDidUnmount() */
            // Effectuer des opérations de nettoyage ici, telles que la suppression des abonnements, l'annulation des requêtes, etc.
          };
    },[location.pathname]); //[location.pathname, userFromState]); // Le tableau de dépendances est vide ici, ce qui signifie que l'effet sera exécuté une seule fois après le premier rendu


    const isPrivateRoute = () => {
        // Vérifier si la page actuelle est parmi les pages exclues
        return Object.values(PrivateRoutesEnum)
        .find((value) => value === location.pathname);
    }

    const handleResendSecurityCode = (success:boolean, message: string) => {
        // Notification utilisateur et redirection vers le login
        if(success){
            dispatch(setSnackbar({ open: true, message: message ?? "Le code de vérification a bien été envoyé", severity: 'success' }));
        } else {
            dispatch(setSnackbar({ open: true, message: message ??"Envoi du code impossible", severity: 'error' }));
        }
    }

    const handleAccountValidation = (success:boolean, message: string) => {
        // Notification utilisateur et redirection vers le login
        if(success){
            dispatch(setSnackbar({ open: true, message: message ?? "Votre compte est activé !", severity: 'success' }));
            history.push('/login');
        } else {
            dispatch(setSnackbar({ open: true, message: message ??"Activation de votre compte impossible", severity: 'error' }));
        }
    }

    const isDemoEnv = () => {
        let hostname:string = window && window.location && window.location.hostname;
        // Sommes-nous sur l'environnement de démonstration ?
        if(hostname){
            return hostname.includes('demo');
        }
        return false;
    }

    return (
        <>
            {/** Bandeau de DEMO le cas échéant */}
            {isDemoEnv() && <Box sx={{ backgroundColor:PALETTE_THIRD_COLOR_MAIN, position:'fixed', width: '100%', textAlign: 'center' }}>
                <Typography variant='body2'><i>Environnement de démonstration ...</i></Typography>
            </Box>}

            <Box sx={{ paddingTop: isDemoEnv() ? 2 : 0 }}>
                {/* Barre de navigation globale */}
                {isPrivateRoute() && Boolean(readCookie(COOKIE_INDIVIDUAL_TOKEN)) && <MovaAppBar activeMenuItem={currentRoute.replace('/', '')} />}
                {/* Le système de gestion des routes de l'application */}
                <Switch>
                    {/* <PrivateRoute exact path="/planning" component={() => <Planning isAdmin={true} />} /> */}
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/signup" component={SignUp} />

                    <Route exact path="/password" component={() => <AccountValidation 
                        resetPassword={true}
                        smsValidation={true}
                        movaAppType={MovaAppType.INDIVIDUAL}
                        onSubmit={handleAccountValidation} />} />

                    <Route exact path="/forgot-password" component={() => <AccountValidation 
                        resetPassword={true}
                        smsValidation={false}
                        movaAppType={MovaAppType.INDIVIDUAL}
                        onSubmit={handleAccountValidation} />} />

                    <Route exact path="/validate" component={() => <AccountValidation 
                        resetPassword={false}
                        smsValidation={true}
                        movaAppType={MovaAppType.INDIVIDUAL}
                        onSubmit={handleAccountValidation} 
                        onResendSecurityCode={handleResendSecurityCode} />} />    

                    <Route exact path="/validate-account" component={() => <AccountValidation 
                        resetPassword={false}
                        smsValidation={false}
                        movaAppType={MovaAppType.INDIVIDUAL}
                        onSubmit={handleAccountValidation} 
                        onResendSecurityCode={handleResendSecurityCode} />} />

                    {/** Cette route n'est pas privée, mais elle contrôle l'authentification et redirige l'utilisateur le cas échéant */}
                    <Route path="/garage/:id" component={() => <Garage />}  />
                    <PrivateRoute exact path={["/", "/home"]}  component={() => <Home activeTab={TabItems.HOME} />} />
                    <PrivateRoute exact path={"/appointments"}  component={() => <Home activeTab={TabItems.APPOINTMENTS} />} />
                    <PrivateRoute exact path={"/vehicles"}  component={() => <Home activeTab={TabItems.VEHICLES} />} />
                    <PrivateRoute exact path="/profile" component={() => <ProfileDialog open={true} onClose={() => {history.push('/home');}} />} />
                    <PrivateRoute exact path="/settings" component={() => <SettingsDialog open={true} onClose={() => {history.push('/home');}} />} />

                    {/* Toujours placer cette route à la fin de la liste pour gérer les 404 */}
                    <Route component={PageNotFound} />
                </Switch>
            </Box>
        </>
    );
};


export default App;
