import { GenderSelector, MovaDialog, MovaFormField, User, capitalizeFirstLetter, validateEmail, validateField, validatePhoneNumber } from '@movalib/movalib-commons';
import { Alert, Box, Button, SelectChangeEvent, TextField, useMediaQuery, useTheme } from '@mui/material';
import { useState, type FC, useEffect } from 'react';
import UserService from '../services/UserService';
import { useDispatch } from 'react-redux';
import Logger from '../helpers/Logger';
import { setSnackbar } from '../slices/snackbarSlice';
import ConfirmIcon from '@mui/icons-material/Check';
import Loader from '../components/Loader';
import { setUserData } from '../slices/userSlice';

interface ProfileDialogProps {
    open:boolean
    onClose: () => void;
}

type UserProfileForm = {
    email: MovaFormField,
    firstname: MovaFormField,
    lastname: MovaFormField,
    gender: MovaFormField,
    birthDate: MovaFormField,
    phoneNumber: MovaFormField
  }
  
  const initialFormState = {
    email: { value: '', error: '', isValid: true },
    firstname: { value: '', error: '', isValid: true },
    lastname: { value: '', error: '', isValid: true },
    gender: { value: '', error: '', isValid: true },
    birthDate: { value: '', error: '', isValid: true },
    phoneNumber: { value: '', error: '', isValid: true }
  };

const ProfileDialog: FC<ProfileDialogProps> = ({ open, onClose }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch();
    const [form, setForm] = useState<UserProfileForm>(initialFormState);
    const [user, setUser] = useState<User | undefined>();
    const [loading, setLoading] = useState<boolean>(false); 
    const [localOpen, setLocalOpen] = useState<boolean>(open); 
    const [message, setMessage] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');

    useEffect(() => {

        purgeLocalState();
        refreshUser();
        setLocalOpen(open);
        
    }, [open]);

    useEffect(() => {

        if(user){
            setForm(prevForm => (
                { ...prevForm, ['email'] : { ...prevForm['email'], value: user.email }}));
            setForm(prevForm => (
                { ...prevForm, ['firstname'] : { ...prevForm['firstname'], value: user.firstname }}));
            setForm(prevForm => (
                { ...prevForm, ['lastname'] : { ...prevForm['lastname'], value: user.lastname }}));    
            setForm(prevForm => (
                { ...prevForm, ['gender'] : { ...prevForm['gender'], value: user.gender ?? ''}}));                
            setForm(prevForm => (
                { ...prevForm, ['birthDate'] : { ...prevForm['birthDate'], value: user.birthDate ?? '' }}));
            setForm(prevForm => (
                { ...prevForm, ['phoneNumber'] : { ...prevForm['phoneNumber'], value: user.phoneNumber ?? '' }}));
        }
        
    }, [user])

    const refreshUser = () => {
        setLoading(true);

        // Récupération des véhicules de l'utilisateur courant
        UserService.getCurrentUser(dispatch)
            .then(user => {

                if(user){
                    // Actualisation en local et dans le localStorage
                    setUser(user);
                    dispatch(setUserData(user));
                }

            }).catch(error => {
                Logger.error(error);
                dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const purgeLocalState = () => {
        setMessage('');
        setErrorMessage('');
        setUser(undefined);
    }
    
    const handleOnClose = () => {
        purgeLocalState();
        onClose();
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        handleChange(e.target.name, e.target.value);
    }

    const handleSelectChange = (e: SelectChangeEvent<string>): void => {
        handleChange(e.target.name, e.target.value);
    }   

    const handleChange = (name: string, value: string) => {
        const fieldName: string = name;
        let fieldValue: string = value;

        // Le contrôle du format du n° de téléphone se fait en live lors de la saisie
        if (fieldName == "phoneNumber"){
            if(!validatePhoneNumber(fieldValue))
            return;
        } 

        // Capitalisation automatique du prénom
        if (fieldName == "firstname"){
            fieldValue = capitalizeFirstLetter(fieldValue);
        }

        // Uppercase automatique pour le nom
        if (fieldName == "lastname"){
            fieldValue = fieldValue.toUpperCase();
        }
        
        const newField: MovaFormField = { [fieldName]: { value: fieldValue, isValid: true } };

        setForm({ ...form, ...newField});
    }

    const validateForm = () => {

        let newForm: UserProfileForm = { ...form };

        // Validator pour les champs obligatoires
        newForm.firstname = validateField(form.firstname, value => !!value, 'Champ obligatoire');
        newForm.lastname = validateField(form.lastname, value => !!value, 'Champ obligatoire');
        newForm.email = validateField(form.email, value => !!value, 'Champ obligatoire');
        newForm.gender = validateField(form.gender, value => !!value, 'Champ obligatoire');
        newForm.birthDate = validateField(form.birthDate, value => !!value, 'Champ obligatoire');

        // Validator pour l'email
        newForm.email = validateField(form.email, validateEmail, 'Adresse email invalide');

        // Validator 'phoneNumber'
        if(newForm.phoneNumber?.value){
            newForm.phoneNumber = validateField(form.phoneNumber, value => value.length == 10, 'Le n° de téléphone est invalide');
        }

        setForm(newForm);

        return newForm.firstname.isValid && newForm.lastname.isValid && newForm.email.isValid && 
            newForm.gender.isValid && newForm.birthDate.isValid && newForm.phoneNumber.isValid;
    }

    const handleSubmit = () => {

        // Création du véhicule pour l'utilisateur courant
        if(validateForm()){

            setLoading(true);

            // Création de la requête
            let req = {
                firstname: form.firstname.value,
                lastname: form.lastname.value,
                email: form.email.value,
                gender: form.gender.value,
                birthDate: form.birthDate.value,
                phoneNumber: form.phoneNumber?.value
            }
    
            UserService.updateUserProfile(req)
                .then((response) => {
            
                    purgeLocalState();
            
                    // Affichage notification utilisateur
                    setMessage(response);
                    //dispatch(setSnackbar({ open: true, message: response, severity: 'success' }));
            
                    // Actualisation de l'utilisateur
                    refreshUser();
            
                }).catch(error => {
                    Logger.error(error);
                    setErrorMessage(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    const isSubmitAuthorized = () => {

        let changeDetected: boolean = true;

        if(form && user){            
             // On active le bouton de soumission si au moins une des valeurs a été modifiée
            changeDetected = (user?.firstname !== form.firstname.value)
                || (user?.lastname !== form.lastname.value)
                || (user?.email !== form.email.value)
                || ((user?.gender ?? '') !== form.gender.value)
                || ((user?.birthDate ?? '') !== form.birthDate.value)
                || ((user.phoneNumber ?? '') !== form.phoneNumber.value);
        }

        return !changeDetected;
    }

    return (
        <>
        {user && 
            <MovaDialog 
                fullScreen={isMobile} 
                open={localOpen} 
                onClose={handleOnClose} 
                leafImageColor="pink" 
                title="PROFIL"
                actions={
                    <Button onClick={handleSubmit} color="primary" sx={{ width: '90%' }} variant='contained'
                                disabled={isSubmitAuthorized()}>
                        <ConfirmIcon sx={{ mr: 1 }} />Enregistrer
                    </Button>
                }
            >
                <Loader loading={loading} />
                
                <Box component="form" noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        autoFocus
                        fullWidth
                        id="firstname"
                        label="Prénom"
                        name="firstname"
                        autoComplete="given-name"                    
                        onChange={e => handleInputChange(e)}
                        value={form.firstname.value}
                        error={Boolean(form.firstname.error)}
                        helperText={form.firstname.error}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="lastname"
                        label="Nom"
                        name="lastname"
                        autoComplete="family-name"                     
                        onChange={e => handleInputChange(e)}
                        value={form.lastname.value}
                        error={Boolean(form.lastname.error)}
                        helperText={form.lastname.error}
                      />                    
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Adresse email"
                        name="email"
                        autoComplete="email"
                        onChange={e => handleInputChange(e)}
                        value={form.email.value}
                        error={!form.email.isValid}
                        helperText={form.email.error}
                    />

                    <GenderSelector handleSelectChange={handleSelectChange} form={form} required sx={{ width:'42%', mr: 2, float:'left'}} />

                    <TextField 
                        margin="normal"
                        required
                        name="birthDate"
                        label="Date de naissance" 
                        type="date" 
                        InputLabelProps={{
                            shrink: true,
                        }}
                        autoComplete="bday"
                        value={form.birthDate.value}
                        error={!form.birthDate.isValid}
                        helperText={form.birthDate.error}        
                        onChange={(e) => handleInputChange(e)}
                        sx={{ width:'50%', float: 'right'}} 
                    />

                    <TextField
                        margin="normal"
                        fullWidth
                        id="phoneNumber"
                        label="N° de téléphone"
                        name="phoneNumber"
                        autoComplete="tel"                      
                        onChange={e => handleInputChange(e)}
                        value={form.phoneNumber?.value}
                        error={Boolean(form.phoneNumber?.error)}
                        helperText={form.phoneNumber?.error}
                      />

                </Box>

                {message && <Alert severity='success' sx={{ mb: 2 }}>{message}</Alert>}
                {errorMessage && <Alert severity='error' sx={{ mb: 2 }}>{errorMessage}</Alert>}

                {/** Alerte spécifique dans le cas d'une modification de l'adresse email */}
                {(user && user.email !== form.email.value &&
                    <Alert severity='warning' sx={{ mb: 2 }}>Attention, votre email a été modifié. 
                    Vous allez recevoir un email d'activation afin de pouvoir l'utiliser.</Alert>
                )}

            </MovaDialog>
        }
        </>
    );
}

export default ProfileDialog;
