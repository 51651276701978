// slices/userSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User, Vehicle } from "@movalib/movalib-commons";

interface UserState {
    user: User | undefined;
    isAuthenticated: boolean;
}

const initialState: UserState = {
    user: undefined,
    isAuthenticated: false
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<User | undefined>) => { state.user = action.payload; },
    setAuthenticatedState: (state, action: PayloadAction<boolean>) => { state.isAuthenticated = action.payload },
    setUserVehicles: (state, action: PayloadAction<Vehicle[]>) => { 
      if(state.user) {
        state.user.vehicles = action.payload;
      }
    }
  },
});

export const { setUserData, setAuthenticatedState, setUserVehicles } = userSlice.actions;

export default userSlice.reducer;
