import { Document, MovaDialog, Vehicle } from '@movalib/movalib-commons';
import { useState, type FC, useEffect, CSSProperties } from 'react';
import { useDispatch } from 'react-redux';
import VehicleService from '../services/VehicleService';
import { setSnackbar } from '../slices/snackbarSlice';
import { Button, darken, useMediaQuery, useTheme } from '@mui/material';
import VehicleFullCard from '../components/VehicleFullCard';
import CancelIcon from '@mui/icons-material/CloseRounded';
import EditIcon from '@mui/icons-material/EditRounded';
import Logger from '../helpers/Logger';

interface VehicleDetailsDialogProps {
    open: boolean,
    vehicleId: number,
    onClose: (refresh:boolean) => void;
}

const VehicleDetailsDialog: FC<VehicleDetailsDialogProps> = ({ open, vehicleId, onClose }) => {

    const theme = useTheme();

    const dispatch = useDispatch();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [localOpen, setLocalOpen] = useState<boolean>(open); 
    const [loading, setLoading] = useState<boolean>(false);   
    const [vehicle, setVehicle] = useState<Vehicle>();   

    const dialogTitleStyle: CSSProperties = {
        color: theme.palette.text.primary,
        fontWeight: 400
    }

    useEffect(() => {

        setLocalOpen(open);
        refreshVehicle();

    }, [open]);


    const refreshVehicle = () => {

        setLoading(true);
        // Récupération des véhicules de l'utilisateur courant
        VehicleService.getVehicleDetails(dispatch, vehicleId)
            .then(vehicle => {
                if(vehicle){
                    setVehicle(vehicle);
                }

            }).catch(error => {
                Logger.error(error);
                dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const purgeLocalState = () => {
        setVehicle(undefined);
    }

    const handleOnClose = () => {
        purgeLocalState();
        setLocalOpen(false);
        onClose(false);
    }

    const handleOnVehicleUpdate = () => {
        // On refresh le véhicule au callback d'édition de la fiche
        refreshVehicle();
    }

    const handleOnVehicleDelete = () => {
        // On ferme la boite de dialogue (avec demande de refresh de la l'écran précédent)
        purgeLocalState();
        setLocalOpen(false);
        onClose(true);
    }

    return (
        <>
        {vehicle &&
            <MovaDialog fullScreen={isMobile} open={localOpen} onClose={handleOnClose} leafImageColor="green" 
                title="VOTRE VÉHICULE" titleStyle={dialogTitleStyle}
            >
                <VehicleFullCard vehicle={vehicle} 
                    onVehicleUpdate={handleOnVehicleUpdate}
                    onVehicleDelete={handleOnVehicleDelete} />
                
            </MovaDialog>
        }
        </>
    );
}

export default VehicleDetailsDialog;
