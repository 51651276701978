import { Garage, MovaDialog, MovaFormField, MovaVehicleTireField, Vehicle, VehicleTire, getApplicationShortLabel, getApplicationsShortLabels } from '@movalib/movalib-commons';
import { useState, type FC, CSSProperties, useEffect } from 'react';
import { Alert, Box, Button, Card, Grid, Typography, darken, useMediaQuery, useTheme } from '@mui/material';
import { flexCenter } from '../../helpers/Tools';
import CarIcon from '@mui/icons-material/CarRepairRounded';
import AddIcon from '@mui/icons-material/AddRounded';
import InfoIcon from '@mui/icons-material/InfoRounded';
import UserService from '../../services/UserService';
import { setUserVehicles } from '../../slices/userSlice';
import { setSnackbar } from '../../slices/snackbarSlice';
import { useDispatch } from 'react-redux';
import VehicleSimpleCard from '../../components/VehicleSimpleCard';
import NextIcon from '@mui/icons-material/ArrowForwardIosRounded';
import AppointmentSlotsDialog from './AppointmentSlotsDialog';
import { useLocation } from 'react-router-dom';
import AppointmentsIcon from '@mui/icons-material/TodayRounded';
import Logger from '../../helpers/Logger';
import EmptyCard from '../../components/EmptyCard';
import AddVehicleDialog from '../AddVehicleDialog';
import { PrestationRequest } from '../../helpers/Types';
import { FLAT_PRESTATION_CODE, TIRE_PRESTATION_CODE } from '../../helpers/Constants';
import TirePicture from "../../assets/images/flan_pneu.png";

interface AppointmentVehicleProps {
    open: boolean,
    garage:Garage,
    prestations: PrestationRequest[],
    otherReason?: string,
    onClose: () => void;
}

export type MovaVehicleForm = {
    tireSize: MovaFormField,
    tireWidth: MovaFormField,
    tireHeight: MovaFormField,
    tireDiameter: MovaFormField,
    tireSpeedIndex: MovaFormField
}

const initialUserFormState = {
    tireSize: { value: null, isValid: true },
    tireWidth: { value: '', isValid: true },
    tireHeight: { value: '', isValid: true },
    tireDiameter: { value: '', isValid: true },
    tireSpeedIndex: { value: '', isValid: true }
}

const AppointmentVehicleDialog: FC<AppointmentVehicleProps> = ({ open, garage, prestations, otherReason, onClose }) => {

    const theme = useTheme();
    const location = useLocation();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch();
    const [selectedVehicle, setSelectedVehicle] = useState<Vehicle>();
    const [localOpen, setLocalOpen] = useState<boolean>(open);
    const [vehicles, setVehicles] = useState<Vehicle[]>([]);
    const [openTireSizeSetup, setOpenTireSizeSetup] = useState<boolean>(false);
    const [openAppointmentSlots, setOpenAppointmentSlots] = useState<boolean>(false);
    const [openAddVehicle, setOpenAddVehicle] = useState(false);
    // Formulaire utilisé pour les modifications d'informations sur le véhicule
    const [form, setForm] = useState<MovaVehicleForm>(initialUserFormState);

    const dialogTitleStyle: CSSProperties = {
        color: darken(theme.palette.primary.main, 0.2),
        fontWeight: 700
    }

    useEffect(() => {

        if(open){
            refreshUserVehicles();
        }

        setLocalOpen(open);

    }, [open]);

    const refreshUserVehicles = () => {

        // Récupération des véhicules de l'utilisateur courant
        UserService.getUserVehicles(dispatch)
            .then(vehicles => {

                if(vehicles && vehicles.length > 0){
                    // Persistance des véhicules dans le state global
                    dispatch(setUserVehicles(vehicles));
                    setVehicles(vehicles);
                } else {
                    setVehicles([]);
                    // On ouvre automatiquement la boite de dialogue d'ajout d'un véhicule
                    setLocalOpen(false);
                    setOpenAddVehicle(true);
                }

            }).catch(error => {
                Logger.error(error);
                dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
            });
    }

    const purgeLocalState = () => {
        setSelectedVehicle(undefined);
        setOpenTireSizeSetup(false);
        setForm(initialUserFormState);
        setVehicles([]);
    }

    const handleOnClose = () => {
        purgeLocalState();
        onClose();
    }

    const handleVehicleCardClick = (vehicle: Vehicle) => {
        if(vehicle){
            // Contrôle spécifique si une des prestations inclus du pneumatique mais que la taille n'est pas précisée
            if((prestations.map(p => p.code).includes(TIRE_PRESTATION_CODE)
                || prestations.map(p => p.code).includes(FLAT_PRESTATION_CODE))
                && (!vehicle.tireDiameter || !vehicle.tireHeight || !vehicle.tireWidth || !vehicle.tireSpeedIndex)){

                setSelectedVehicle(vehicle);
                setOpenTireSizeSetup(true);

            } else {
                setSelectedVehicle(vehicle);
            }
        }
    }

    const handleCancelTireSizeSetup = () => {
        setSelectedVehicle(undefined);
        setOpenTireSizeSetup(false);
        setForm(initialUserFormState);
    }

    const handleOnClickAvailableSlots = () => {
        setLocalOpen(false);
        setOpenAppointmentSlots(true);
    }

    const handleOnCloseAppointmentSlots = () => {
        setOpenAppointmentSlots(false);
        setLocalOpen(true);
    }

    const handleOpenAddVehicle = () => {
        setLocalOpen(false);
        // Ouverture formulaire d'ajout d'un premier véhicule
        setOpenAddVehicle(true);
    }

    const handleConfirmAddFirstVehicle = (returnVehiclePlate: string, returnVehicleDescription: string) => {
        // On rafraîchit la liste des véhicules de l'utilisateur connecté
        refreshUserVehicles();

        // On ferme la boite de dialogue
        setOpenAddVehicle(false);
        setLocalOpen(true);
    }

    const handleOnCloseAddVehicle = () => {
        setOpenAddVehicle(false);
        setLocalOpen(true);
    }

    const handleOnChangeVehicleTire = (vehicleTire: VehicleTire, isValid:boolean) => {
        setForm(prevForm => (
            { ...prevForm, ['tireSize'] : { ...prevForm['tireSize'], value: vehicleTire, isValid: isValid }}));
    }

    return (
        <>
            {garage &&
                <MovaDialog fullScreen={isMobile} open={localOpen} onClose={handleOnClose} leafImageColor="yellow" title={garage.name} titleStyle={dialogTitleStyle}
                actions={
                    <Button onClick={handleOnClickAvailableSlots} color="primary" sx={{ width: '90%' }} variant='contained'
                                disabled={(!selectedVehicle && !openTireSizeSetup)
                                || (openTireSizeSetup && !(form.tireSize && form.tireSize.value != null && form.tireSize.isValid))}>
                        <NextIcon sx={{ mr: 1 }} />Suivant
                    </Button>
                }
                >
                    {!openTireSizeSetup &&
                    <>
                        {/** Titre */}
                        <Typography variant="h6" component="div" color={theme.palette.text.primary} style={flexCenter} sx={{ mt: 2 }}>
                            <CarIcon sx={{ mr: 1 }} />Pour quel véhicule ?
                        </Typography>

                        <Grid container sx={{ width: '100%', minHeight: '400px', flexDirection: 'column'}} >
                            {vehicles && vehicles?.map((vehicle, index) => (
                                <Grid item xs={12} key={index} sx={{ mt: 5 }} style={flexCenter}>
                                    <VehicleSimpleCard vehicle={vehicle} onClick={handleVehicleCardClick} focused={selectedVehicle && vehicle.id === selectedVehicle.id} />
                                </Grid>
                            ))}

                            {/** AUCUN véhicule */}
                            {vehicles && vehicles.length === 0 &&
                                <Grid item xs={12} sx={{ mt: 3 }} >
                                    <EmptyCard />
                                </Grid>
                            }
                             <Grid item xs={12} sx={{ mt: 1 }} >
                                <Button onClick={handleOpenAddVehicle} color="primary" sx={{ width: '90%', mt: 3, color: theme.palette.primary.dark }} variant='outlined'>
                                    <AddIcon />&nbsp;Ajouter un véhicule
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                    }
                    {openTireSizeSetup &&
                        <Box
                        sx={{ backgroundColor: 'transparent', mt:2, py: 2, mb: 2 }}>
                            <Typography variant="h6" component="div" color={theme.palette.text.primary} style={flexCenter} sx={{ mb: 3, px: 2, }}>
                                Dimension pneumatiques&nbsp;<b>
                                    {prestations
                                        .filter((p) => p.code == TIRE_PRESTATION_CODE || p.code == FLAT_PRESTATION_CODE)
                                        .map((p) => getApplicationsShortLabels(p.applications))
                                    }
                                </b>
                            </Typography>
                            <MovaVehicleTireField
                                vehicleTire={form.tireSize.value}
                                onChangeVehicleTire={handleOnChangeVehicleTire}
                            />

                            <Alert color='success' sx={{ textAlign: 'center', borderRadius: 20, mt: 3 }} style={flexCenter} icon={<InfoIcon />}>
                                <Typography>
                                     La dimension est inscrite sur le flanc de vos pneus (les deux sont identiques).
                                </Typography>
                            </Alert>
                            <img src={TirePicture} style={{
                                position: 'relative',
                                width: '100%',
                                top: '0px',
                                left: '0px',
                                zIndex: 200}} alt='Icone Voiture'>
                            </img>
                            <Button onClick={handleCancelTireSizeSetup} color="primary" sx={{ width: '90%', mt: 3, color: theme.palette.primary.dark }} variant='outlined'>
                                Annuler
                            </Button>
                        </Box>
                    }
                </MovaDialog>
            }

            <AppointmentSlotsDialog
                open={openAppointmentSlots}
                garage={garage}
                prestations={prestations}
                otherReason={otherReason}
                vehicle={selectedVehicle}
                tireSize={form.tireSize.value}
                onClose={handleOnCloseAppointmentSlots} />

            {/* DIALOG BOX */}
            {openAddVehicle && <AddVehicleDialog open={openAddVehicle} title={garage.name} titleStyle={dialogTitleStyle}
                onConfirm={handleConfirmAddFirstVehicle} isFirstVehicle={false} leafImageColor="yellow"
                onClose={handleOnCloseAddVehicle}/>}
        </>
    );
}

export default AppointmentVehicleDialog;
