/** Code des prestations 'Pneumatique' & 'Crevaison' */
export const TIRE_PRESTATION_CODE = "TIRE";
export const FLAT_PRESTATION_CODE = "FLAT";
/**
* Définition d'une troisième couleur dominante via constante (un thème ne contient que primary et secondary)
*  */
export const PALETTE_THIRD_COLOR_MAIN: string = '#F29ABA';
export const PALETTE_THIRD_COLOR_LIGHT: string = '#F1C0D9';
/**
 * Durée d'expiration des cookies par défaut
 */
export const COOKIE_REDIRECT_REQUEST: string = 'movalibRedirect';
export const COOKIE_DEFAULT_EXPIRES: number = 7;
export const COOKIE_STOP_PUSH_ADD_VEHICLE: string = 'stopPushAddVehicle';
export const COOKIE_IS_AUTHENTICATED: string = 'isAuthenticated';
export const COOKIE_USER_ID: string = 'userId';

export const WEEK_DAYS: string[] = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];
export const API_BASE_URL: string | undefined = process.env.REACT_APP_API_URL;  // Saisir " 'http://localhost:3001' " si utilisation de json-server 

export const ID_DEV: boolean = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development');
export const CULTURE: string = 'fr';

export type FormField = {
    value?: any,
    error?: string,
    isValid?: boolean
}