import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { request } from "../helpers/ApiHelper";
import { API_BASE_URL } from "../helpers/Constants";
import { APIMethod } from "../helpers/Enums";
import { Document, User, Vehicle } from "@movalib/movalib-commons";

export default class VehicleService {

    static deleteVehicleDocument(dispatch: Dispatch<AnyAction>, vehicleId: number, documentId: string): Promise<string> {
        return request({
          url: `${API_BASE_URL}/vehicle/${vehicleId}/documents/${documentId}`,
          method: APIMethod.DELETE
        }, dispatch);
    }

    static updateVehicle(dispatch: Dispatch<AnyAction>, vehicleId: number, req: any): Promise<string> {
        return request({
          url: `${API_BASE_URL}/vehicle/${vehicleId}`,
          method: APIMethod.PATCH,
          body: JSON.stringify(req)
        }, dispatch);
    }

    static updateVehicleMileage(dispatch: Dispatch<AnyAction>, vehicleId: number, req: any): Promise<string> {
        return request({
          url: `${API_BASE_URL}/vehicle/${vehicleId}/mileage`,
          method: APIMethod.PATCH,
          body: JSON.stringify(req)
        }, dispatch);
    }

    static uploadVehicleDocument(dispatch: Dispatch<AnyAction>, vehicleId: number, req: FormData): Promise<string> {
        return request({
          url: `${API_BASE_URL}/vehicle/${vehicleId}/documents`,
          method: APIMethod.POST,
          body: req
        }, dispatch);
    }


    static getVehicleDetails(dispatch: Dispatch<AnyAction>, vehicleId: number): Promise<Vehicle>  {
        return request({
            url: `${API_BASE_URL}/vehicle/${vehicleId}`,
            method: APIMethod.GET
        }, dispatch);
    }


    static getVehicleDocuments(dispatch: Dispatch<AnyAction>, vehicleId: number): Promise<Document[] | []>  {
        return request({
            url: `${API_BASE_URL}/vehicle/${vehicleId}/documents`,
            method: APIMethod.GET
        }, dispatch);
    }

    static getVehicleDescription(dispatch: Dispatch<AnyAction>, plate: string): Promise<string | null>  {
        return request({
            url: `${API_BASE_URL}/vehicle/${plate}/description`,
            method: APIMethod.GET
        }, dispatch);
    }
}