import { Backdrop, CircularProgress, useTheme } from "@mui/material";
import { CSSProperties, FunctionComponent } from "react";

interface LoaderProps {
    loading:boolean,
    padding?: number
}

const Loader: FunctionComponent<LoaderProps> = ({ loading, padding = 3 }) => {

    const theme = useTheme();

    const containerStyle: CSSProperties = {
        width:'100%',
        textAlign: 'center',
        paddingTop: theme.spacing(padding),
        paddingBottom: theme.spacing(padding)
    }

    return (
        <>
            {loading && <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                >
                <CircularProgress color="inherit" />
            </Backdrop>
            }   
{/*             {loading &&
                <div style={containerStyle}>
                    <CircularProgress />
                </div>
            } */}
        </>
    );
}

export default Loader;