import React, { FunctionComponent } from 'react';
import { Route, Redirect, RouteProps, RouteComponentProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from './store';
import Cookies from 'js-cookie';
import { readCookie } from './helpers/Tools';
import { COOKIE_INDIVIDUAL_TOKEN } from '@movalib/movalib-commons/dist/src/helpers/CookieUtils';
  
const PrivateRoute: FunctionComponent<RouteProps> = ({ component: Component, ...rest }) => {
  if (!Component) return null; // Si aucun composant n'est passé, on retourne null

  const RenderComponent: FunctionComponent<RouteComponentProps> = (props) => {
    // La lecture depuis le state global n'est pas persistante
    //const isAuthenticated = useSelector((state: RootState) => state.user.isAuthenticated);
    let isAuthenticated:boolean = Boolean(readCookie(COOKIE_INDIVIDUAL_TOKEN));

    if (!isAuthenticated) {    
      return <Redirect to={{ pathname: '/login' }} />
    }

    return <Component {...props} />;
  }

  return <Route {...rest} component={RenderComponent} />;
};
  
export default PrivateRoute;
