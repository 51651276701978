import { Garage, MovaDialog, PartsApplicationType, Vehicle, VehicleTire, formatVehicleTire, getApplicationShortLabel, getApplicationsShortLabels } from '@movalib/movalib-commons';
import { Alert, Button, Card, CardContent, Grid, Typography, darken, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, type CSSProperties, type FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import ConfirmIcon from '@mui/icons-material/Check';
import SummarizeIcon from '@mui/icons-material/SummarizeRounded';
import { flexCenter, formatLocalDateToISOString, getLongFormattedDate, getLongFormattedDateTime } from '../../helpers/Tools';
import AppointmentsIcon from '@mui/icons-material/TodayRounded';
import InfoIcon from '@mui/icons-material/InfoRounded';
import SmartphoneIcon from '@mui/icons-material/SmartphoneRounded';
import SmsIcon from '@mui/icons-material/Sms';
import GarageService from '../../services/GarageService';
import { setSnackbar } from '../../slices/snackbarSlice';
import { useHistory } from 'react-router-dom';
import Logger from '../../helpers/Logger';
import { PrestationRequest } from '../../helpers/Types';
import TirePicture from "../../assets/images/flan_pneu.png";
import { FLAT_PRESTATION_CODE, TIRE_PRESTATION_CODE } from '../../helpers/Constants';

interface AppointmentSummaryDialogProps {
    open: boolean,
    garage:Garage,
    prestations: PrestationRequest[],
    appointmentDate: Date,
    onClose: () => void,
    vehicle: Vehicle,
    tireSize?: VehicleTire,
    otherReason?: string,
}

const AppointmentSummaryDialog: FC<AppointmentSummaryDialogProps> = ({ open, garage, prestations, otherReason, vehicle, tireSize, appointmentDate, onClose }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch();
    const history = useHistory();
    const [localOpen, setLocalOpen] = useState<boolean>(open);
    const [showTireSize, setShowTireSize] = useState<boolean>(false);

    const dialogTitleStyle: CSSProperties = {
        color: darken(theme.palette.primary.main, 0.2),
        fontWeight: 700
    }

    useEffect(() => {
        if(prestations.map(p => p.code).includes(TIRE_PRESTATION_CODE)
            || prestations.map(p => p.code).includes(FLAT_PRESTATION_CODE)) {
                // on affiche la dimension des pneumatiques s'il s'agit d'une prestation pertinente et si les dimension est disponible
                setShowTireSize(tireSize ? true : false);
        }

        setLocalOpen(open);

    }, [open]);

    const purgeLocalState = () => {

    }

    const handleOnClose = () => {
        purgeLocalState();
        setLocalOpen(false);
        onClose();
    }

    const handleOnClickValidate = () => {

        if(garage && prestations && vehicle && appointmentDate){

            // Pour chaque prestation

            let req = {
                //startDate: appointmentDate,
                // IMPORTANT : permet de transmettre le TimeZone correspond à l'horaire choisit, l'API réalise des ajustements selon la zone
                startDate: formatLocalDateToISOString(appointmentDate),
                prestations: prestations,
                vehicleId : vehicle.id,
                tireSize: tireSize,
                otherReason: otherReason
            }

            Logger.info(req)
            GarageService.createGarageAppointment(dispatch, garage.id, req)
                .then(response => {

                    Logger.info(response);

                    // Affichage notification utilisateur
                    dispatch(setSnackbar({ open: true, message: response, severity: 'success' }));

                    // Dernière étape, une fois la demande de RDV enregistrée on retourne à la Home page
                    history.push('/home');

                }).catch(error => {
                    Logger.error(error);
                    dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
                });
        }
    }

    return (
        <>
            {garage && prestations && vehicle && appointmentDate &&
                <MovaDialog fullScreen={isMobile} open={localOpen} onClose={handleOnClose} leafImageColor="yellow" title={garage.name} titleStyle={dialogTitleStyle}
                actions={
                    <Button onClick={handleOnClickValidate} color="primary" sx={{ width: '90%' }} variant='contained'
                                disabled={!garage && (!prestations || !(prestations.length > 0)) && !vehicle && appointmentDate}>
                        <ConfirmIcon sx={{ mr: 1 }} />Valider ma demande
                    </Button>
                }
                >
                    <Typography variant="h6" component="div" color={theme.palette.text.primary} style={flexCenter} sx={{ mt: 2, mb: 3, textDecoration: 'underline' }}>
                        Votre demande de rendez-vous
                    </Typography>
                    {/** Rappel infos du RDV */}
                    <Card variant='outlined' sx={{
                            backgroundColor: 'transparent',
                            overflow: 'visible', m:2, mb: 3  }}
                        >
                        <CardContent sx={{ pt: 2, pb: 0}}>
                            <AppointmentsIcon sx={{ mb: 1 }} />
                            {/** Rappel du créneau */}
                            <Typography variant="subtitle1" component="div" align="center" sx={{ mb:2 }} color="text.primary" >
                                <b>Le {getLongFormattedDateTime(appointmentDate)}</b>
                            </Typography>
                            <Typography variant="subtitle1" component="div" align="center" sx={{ mb:1 }} color="text.primary" >
                                <b>{vehicle.brand} {vehicle.model} {vehicle.version}</b>
                            </Typography>
                            {/** Rappel des prestations sélectionnées */}
                            {garage.prestations && garage.prestations.map((prestation, index) => (
                                <Grid container>
                                    {prestations.map(p => p.id).includes(prestation.id) &&
                                        <Grid item xs={12}>
                                            <Typography variant="body1" color="text.primary" sx={{ textAlign: 'center'}}>
                                                {prestation.name}{' '}
                                                {prestations
                                                    .filter((p) => p.id === prestation.id)
                                                    .map((p) => getApplicationsShortLabels(p.applications))
                                                }
                                            </Typography>
                                            {prestation.code === 'OTHER' &&
                                                <Typography variant="body1" color="text.primary" sx={{ textAlign: 'center'}}>
                                                    {otherReason}
                                                </Typography>}
                                        </Grid>
                                    }
                                </Grid>
                            ))}
                            {/** Rappel taille des pneumatiques le cas échéant */}
                            {showTireSize && tireSize &&
                                <><br />
                                <img src={TirePicture} style={{
                                    position: 'relative',
                                    width: '40%',
                                    top: '0px',
                                    left: '0px',
                                    zIndex: 200}} alt='Icone Voiture'>
                                </img><br />
                                    <Typography variant="h6">
                                        <b>{formatVehicleTire(tireSize)}</b>
                                    </Typography>
                                </>
                            }

                        </CardContent>
                    </Card>

                    <Alert severity='success' icon={<SmsIcon />} sx={{ borderRadius: 20}}>Vous recevrez un SMS de confirmation une fois votre demande traitée 😉</Alert>

                </MovaDialog>
            }
        </>
    );
}

export default AppointmentSummaryDialog;
