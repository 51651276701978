import React, { FunctionComponent, useEffect, useState } from "react";
import { AppBar, Box, Container, IconButton, Toolbar, MenuItem, Menu, Typography, Button, Tooltip, Avatar, List, ListItem, ListItemButton, ListItemIcon, Divider, ListItemText, Drawer } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { useHistory } from "react-router-dom";
import { PrivateRoutesEnum, AppBarMenuEnum } from '../helpers/Enums';
import { deleteCookies, findValueByKey } from "../helpers/Tools";
import HeaderLogo from "../assets/images/logo/header_logo_border.png";
import ProfileIcon from '@mui/icons-material/PersonRounded';
import SettingsIcon from '@mui/icons-material/SettingsRounded';
import LogoutIcon from '@mui/icons-material/LogoutRounded';
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { User } from "@movalib/movalib-commons";
import Logger from "../helpers/Logger";
import { TabItems } from "../pages/Home";

type MovaAppBarProps = {
    activeMenuItem?: string
}

const MovaAppBar: FunctionComponent<MovaAppBarProps> = ({ activeMenuItem }) => {

    const history = useHistory();
    // Récupération des données de l'utilisateur connecté
    const currentUser = useSelector((state: RootState) => state.user.user);
    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    const [openUserMenu, setOpenUserMenu] = useState(false);

    useEffect(() => {
        Logger.info(currentUser);
    }, [currentUser, openUserMenu])
  
    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        setOpenUserMenu(true);
    };
  
    const handleCloseNavMenu = () => {
      setAnchorElNav(null);
    };
  
    const handleCloseUserMenu = () => {
        setOpenUserMenu(false);
    };

    const handlePageClick = (page: AppBarMenuEnum) => {

        //Fermeture du menu
        handleCloseUserMenu();

        switch(page){
            case AppBarMenuEnum.ACCUEIL:
                history.push('/home'); 
                return;
            case AppBarMenuEnum.APPOINTMENTS:
                history.push('/appointments');
                return;
            case AppBarMenuEnum.VEHICLES:
                history.push('/vehicles');
                return;    
            case AppBarMenuEnum.PROFILE:
                history.push('/profile');
                return;    
            case AppBarMenuEnum.SETTINGS:     
                history.push('/settings');
                return; 
            case AppBarMenuEnum.LOGOUT:     
                // Suppression des cookies
                deleteCookies();
                // Suppression des données persistées en localStorage
                localStorage.clear();
                history.push('/login');
                return;                 
        }
    }

    const toggleDrawer = (open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
    };
    
    const userMenuList = () => (
        <Box
          sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 , width: 250 }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
             <Box sx={{ flexGrow: 1 }}> {/* Cette Box prendra tout l'espace vertical disponible */}
                <List>
                    <ListItem key={1} disablePadding>
                        <ListItemButton sx={{ height: '100px' }} disableTouchRipple={true} >
                            <ListItemIcon>
                                <Avatar alt="Avatar utilisateur"  sx={{ width:'60px', height: '60px' }}/>
                            </ListItemIcon>
                        </ListItemButton>
                    </ListItem>
                    <ListItem key={2} sx={{ pb: 2 }}><b>{currentUser?.firstname} {currentUser?.lastname}</b></ListItem>
                    <Divider />
                    <ListItem key={3} disablePadding>
                        <ListItemButton onClick={(e) => (handlePageClick(AppBarMenuEnum.PROFILE))}>
                            <ListItemIcon>
                                <ProfileIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Profil"} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem key={3} disablePadding>
                        <ListItemButton onClick={(e) => (handlePageClick(AppBarMenuEnum.SETTINGS))}>
                            <ListItemIcon >
                                <SettingsIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Préférences"} />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box>
            <Box> {/* Cette Box sera positionnée en bas */}
                <Divider />
                <List>
                    <ListItem disablePadding>
                        <ListItemButton onClick={(e) => (handlePageClick(AppBarMenuEnum.LOGOUT))}>
                            <ListItemIcon>
                                <LogoutIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Déconnexion"} />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box>
        </Box>
      );

    const getMenuButton = (menu: AppBarMenuEnum) => (
        <Button size="medium"
            key={menu}
            onClick={() => handlePageClick(menu)}
            sx={{ my: 2, mx:2, color:'text.primary', display: 'block',
                backgroundColor: (activeMenuItem && activeMenuItem.toUpperCase() === menu.toUpperCase()) ? 'text.light' : 'transparent',
            ':hover': {
                bgcolor: 'text.light'}
            }}
        >
            {menu}
        </Button>
    );
    
    return (
        <AppBar position="fixed" sx={{ backgroundColor: 'transparent'}} elevation={0}>
            <Container maxWidth={false}>
                <Toolbar disableGutters variant="dense" >

                    <Box>
                        <Tooltip title="Ouvrir le menu">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar alt="Avatar utilisateur" src="/static/images/avatar/2.jpg" sx={{ width:'30px', height: '30px' }}/>
                            </IconButton>
                        </Tooltip>
                        <Drawer
                            anchor={'left'}
                            open={openUserMenu}
                            onClose={handleCloseUserMenu}
                        >
                            {userMenuList()}
                        </Drawer>
                    </Box>

                    <IconButton edge="start" color="inherit" aria-label="logo"
                        disableRipple
                        sx={{
                            flexGrow: 1,
                            mr: 2,
                            display: { xs: 'flex', md: 'flex' },
                            ':hover': {
                                bgcolor: 'transparent'}
                        }}>
                        <img src={HeaderLogo} alt="Logo Movalib" style={{ height:'45px' }} />
                    </IconButton>


                    <Box sx={{ flexGrow: 2, display: { xs: 'none', md: 'flex' }, justifyContent: 'end' , mr: 2  }}>                       
                        {getMenuButton(AppBarMenuEnum.ACCUEIL)}
                        {getMenuButton(AppBarMenuEnum.APPOINTMENTS)}
                        {getMenuButton(AppBarMenuEnum.VEHICLES)}
                    </Box>

{/* ACTIVER CE MENU SI BESOIN

                    <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' }}}>
                        <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenNavMenu}
                        color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{
                            display: { xs: 'block', md: 'none' },
                        }}
                        >
                            {Object.entries(AppBarPagesEnum).map(([key, value]) => (
                                <MenuItem key={key} onClick={() => handlePageClick(key)}>
                                    <Typography textAlign="center">{value}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box> */}


                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default MovaAppBar;

function setState(arg0: any) {
    throw new Error("Function not implemented.");
}
