import { DigitalPassportIndex } from '@movalib/movalib-commons';
import type { FC } from 'react';
import DigitalPassportGreen from "../assets/images/speedometer_green.png";
import DigitalPassportOrange from "../assets/images/speedometer_orange.png";
import DigitalPassportRed from "../assets/images/speedometer_red.png";

interface MovaDigitalPassportProps {
    digitalPassportIndex: DigitalPassportIndex
}

const MovaDigitalPassport: FC<MovaDigitalPassportProps> = ({ digitalPassportIndex }) => {

    const getDigitalPassportImage = () => {
        switch(digitalPassportIndex){
            case DigitalPassportIndex.A:
                return DigitalPassportGreen;
            case DigitalPassportIndex.B:
                return DigitalPassportOrange;
            case DigitalPassportIndex.C:
                return DigitalPassportRed;                
        }
    }

    const handleOnClick = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
        e.preventDefault()

        // On affiche la popup explicative sur la passport digital
        

    }

    return (
        <>
            {digitalPassportIndex && 
                <img src={getDigitalPassportImage()} style={{
                    position: 'relative',
                    width: '25%',
                    top: '-25px',
                    right: '-15px',
                    zIndex: 200}} alt='Passport Digital' 
                    onClick={(e) => handleOnClick(e)}/>
            }
        </>
    );
}

export default MovaDigitalPassport;
