import { useState, type FC, useEffect } from 'react';
import GarageDialog from '../dialog/GarageDialog';
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom';
import { COOKIE_REDIRECT_REQUEST } from '../helpers/Constants';
import { createCookie, readCookie } from '../helpers/Tools';
import UserService from '../services/UserService';
import { setUserVehicles } from '../slices/userSlice';
import { Logger } from '@movalib/movalib-commons';
import { setSnackbar } from '../slices/snackbarSlice';
import { useDispatch } from 'react-redux';
import AddVehicleDialog from '../dialog/AddVehicleDialog';
import { COOKIE_INDIVIDUAL_TOKEN } from '@movalib/movalib-commons/dist/src/helpers/CookieUtils';

interface RouteParams {
    id: string;  // Définissez ici les paramètres de route attendus
}

interface GarageProps {

}

const Garage: FC<GarageProps> = ({}) => {

    const { id } = useParams<RouteParams>(); // Récupère l'identifiant du garage depuis l'URL
    const location = useLocation();  // Récupère l'objet location qui contient les informations sur l'URL courante
    const [openGarageDialog, setOpenGarageDialog] = useState<boolean>(false);  
    const history = useHistory();
    const dispatch = useDispatch();
    const [openAddVehicle, setOpenAddVehicle] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);

    // Pour obtenir le paramètre 'redirect', vous pouvez utiliser l'API URLSearchParams
    const queryParams = new URLSearchParams(location.search);
    const redirect = queryParams.get('redirect');  // Obtient la valeur du paramètre 'redirect' depuis l'URL

    // Si l'utilisateur n'est pas authentifié, redirection vers le login avec stockage de l'url de redirection initiale
    let isAuthenticated:boolean = Boolean(readCookie(COOKIE_INDIVIDUAL_TOKEN));

    useEffect(() => {
        // On ouvre la fiche garage
        setOpenGarageDialog(true);

        if (isAuthenticated) {
            //refreshUserVehicles();
        }
      }, [location]);


    const handleOnCloseGarageDialog = () => {
        //if(isAuthenticated){
            setOpenGarageDialog(false);
            // Redirection vers la home page
            history.push('/home');
        //}
      }

      const refreshUserVehicles = () => {

        setLoading(true);
        // Récupération des véhicules de l'utilisateur courant
        UserService.getUserVehicles(dispatch)
            .then(vehicles => {

                if(vehicles && vehicles.length > 0){
                    // Persistance des véhicules dans le state global
                    dispatch(setUserVehicles(vehicles));
                    // On ouvre la fiche garage
                    setOpenGarageDialog(true);
                } else {
                    // On déclenche l'ouverture de l'ajout d'un véhicule
                    setOpenAddVehicle(true);
                }

            }).catch(error => {
                Logger.info(error);
                dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleConfirmAddFirstVehicle = (returnVehiclePlate: string, returnVehicleDescription: string) => {
      // On ferme la boite de dialogue
      setOpenAddVehicle(false);

      // On rafraîchit la liste des véhicules de l'utilisateur connecté
      refreshUserVehicles();
    } 

    const handleOnCloseAddVehicle = () => {
      setOpenAddVehicle(false);
      // On ouvre la fiche garage
      setOpenGarageDialog(true);
    }

    return (
    <>
      {/* DIALOG BOX */}
{/*       {openAddVehicle && <AddVehicleDialog open={openAddVehicle} 
          onConfirm={handleConfirmAddFirstVehicle} isFirstVehicle={false} 
          onClose={handleOnCloseAddVehicle}/>} */}

      {openGarageDialog && 
        <GarageDialog open={openGarageDialog} garageId={id} onClose={handleOnCloseGarageDialog} />
      }
    </>
    );
}

export default Garage;
