import { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AuthenticationService from '../services/AuthenticationService';
import { useDispatch } from 'react-redux';
import { setUserData, setAuthenticatedState } from '../slices/userSlice';
import { MovaSignUp, MovaUserSignUpForm, MovaAppType, User } from "@movalib/movalib-commons";
import { AlertColor } from "@mui/material";
import UserService from '../services/UserService';
import { setSnackbar } from '../slices/snackbarSlice';
import Logger from '../helpers/Logger';
import useDarkMode from '../helpers/hooks/useDarkMode';

const SignUp: FunctionComponent = () => {

    const isDarkMode = useDarkMode();
    const dispatch = useDispatch();
    const history = useHistory();
    const [alertMessage, setAlertMessage] = useState<string>("");    
    // La sévérité est initialiée à "error" par défaut
    const [alertSeverity, setAlertSeverity] = useState<AlertColor>('error');    
    const [loading, setLoading] = useState(false);
    
    const handleSubmit = async (form: MovaUserSignUpForm) => {

        setLoading(true);

        try {

            // On prépare la query
            let query = {
                email : form.email.value,
                password : form.password.value,
                firstname : form.firstname.value,
                lastname : form.lastname.value,  
                //gender : form.gender.value,      
                //birthDate  : form.birthDate.value,
                phoneNumber : form.phoneNumber.value  
            }

            UserService.signUp(query)
                .then(response => {

                    Logger.info(response);

                    // Affichage notification utilisateur
                    dispatch(setSnackbar({ open: true, message: response ?? "Votre compte est créé ! Cliquez sur le lien reçu par email pour l'activer 💪", severity: 'success' }));

                    // C'est tout, l'utilisateur doit désormais valider son compte avant de pouvoir se connecter
                    history.push('/login');
                    
                }).catch(error => {
                    Logger.error(error);
                    setAlertMessage(error);
                });

        }catch (error){
            console.error('Error occurred during submission:', error);
        }finally {
            setLoading(false);
        }
    }

    return (
        <MovaSignUp 
            movaAppType={MovaAppType.INDIVIDUAL} 
            onSubmit={handleSubmit} 
            alertMessage={alertMessage} 
            alertSeverity={alertSeverity} 
            loading={loading} />
    );
};

export default SignUp;