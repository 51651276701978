import { AnyAction, Dispatch } from "redux";
import { APIResponse, User, request } from "@movalib/movalib-commons";
//import { request } from "../helpers/ApiHelper";
import { API_BASE_URL } from "../helpers/Constants";
import { APIMethod } from "../helpers/Enums";
import { createCookie } from "../helpers/Tools";
import UserService from "./UserService";
import { setUserData } from '../slices/userSlice';
import Logger from "../helpers/Logger";
import { COOKIE_INDIVIDUAL_TOKEN } from "@movalib/movalib-commons/dist/src/helpers/CookieUtils";

export default class AuthenticationService {

    static async forgotPassword(req: any): Promise<APIResponse<string>> {
        return request({
          url: `${API_BASE_URL}/forgot-password`,
          method: APIMethod.POST,
          body: JSON.stringify(req)
      });
    }

    static validateToken(token: string): Promise<APIResponse<string>> {
      return request({
          url: `${API_BASE_URL}/validate-token`,
          method: APIMethod.GET,
      });
    }

    static async registerUser(dispatch: Dispatch<AnyAction> | null, token: string): Promise<User | null> {
      if(token){
        Logger.info(token);
        createCookie(COOKIE_INDIVIDUAL_TOKEN, token);

        // Chargement de l'utilisateur authentifié
        let user =  await UserService.getCurrentUser();

        // On charge les données utilisateur dans le state global
        if(dispatch){
          dispatch(setUserData(user));
        }

        if(!user)
          return Promise.reject('Erreur au chargement de votre profil');

        return Promise.resolve(user);
      }

      return Promise.reject('Erreur au chargement de votre profil');
    }

    static async login(dispatch: Dispatch<AnyAction> | null, phoneEmail: string, password: string): Promise<User | null> {
        try {
          let token = await request({
              url: `${API_BASE_URL}/login`,
              method: APIMethod.POST,
              body: JSON.stringify({
                phoneEmail : phoneEmail,
                password: password
              })
          });

          return this.registerUser(dispatch, token.data);

        } catch (error) {
          console.error('Error occurred during login:', error);
          return Promise.reject(error);
      }
    }   
    
}