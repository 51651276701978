import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { request } from "../helpers/ApiHelper";
import { API_BASE_URL } from "../helpers/Constants";
import { APIMethod } from "../helpers/Enums";
import { Garage } from "@movalib/movalib-commons";
import { objectToMap } from "../helpers/Tools";
import { format, formatISO, startOfDay } from "date-fns";

export default class GarageService {

    static createGarageAppointment(dispatch: Dispatch<AnyAction>, garageId: string, req: any): Promise<string> {
        return request({
          url: `${API_BASE_URL}/garage/${garageId}/appointments`,
          method: APIMethod.POST,
          body: JSON.stringify(req)
        }, dispatch);
    }
    
    static getGarageAvailableSlots(dispatch: Dispatch<AnyAction>, garageId: string, prestations: number[], startDate?: Date): Promise<Map<Date, string[]>> {
        
        // Création d'une chaîne de paramètres pour les identifiants de prestations
        let formattedString: string = prestations.map(p => `pId=${p}`).join('&');

        // Ajout de la startDate si transmise
        if(startDate && startDate instanceof Date){
            const startDateWithoutTime = startOfDay(startDate);
            const formattedStartDate =  format(startDateWithoutTime, "yyyy-MM-dd'T'HH:mm:ss");
            formattedString = `${formattedString} &startDate=${encodeURIComponent(formattedStartDate)}`
        }
        
        return request({
            url: `${API_BASE_URL}/garage/${garageId}/available-slots?${formattedString}`,
            method: APIMethod.GET,
        }, dispatch)
        // On ajoute une conversion de l'objet en Map
        .then(response => objectToMap(response));
    }

    static getGarageInfos(dispatch: Dispatch<AnyAction>, garageId: string): Promise<Garage> {
        return request({
            url: `${API_BASE_URL}/garage/${garageId}`,
            method: APIMethod.GET,
        }, dispatch);
    }

    static searchGarages(dispatch: Dispatch<AnyAction>, query: string): Promise<Garage[]> {
        return request({
            url: `${API_BASE_URL}/garages?query=${query}`,
            method: APIMethod.GET,
        }, dispatch);
    }
}