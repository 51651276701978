
export enum APIMethod {
    GET = 'GET',
    PUT = 'PUT',
    PATCH = 'PATCH',
    POST = 'POST',
    DELETE = 'DELETE'
}

export enum ActionType {
    DELETE, CREATE, UPDATE, NONE
}

/**
 * Tableau reprenant la liste des routes privées.
 * APP.tsx : On l'utilise pour savoir si nous devons afficher 
 * la barre de menu et identifier le menu actif.
 */
export const PrivateRoutesEnum = Object.freeze({ 
    ROOT: "/",
    HOME:  "/home",
    LOGOUT:  "/logout",
    PROFILE: "/profile",
    SETTINGS:  "/settings",
    SUPPORT: "/support",
    APPOINTMENT: "/appointments",
    VEHICLES: "/vehicles"
});

/**
 * Tableau reprenant la liste des entrées de menu de la barre de navigation globale.
 */
export enum AppBarMenuEnum { 
    ACCUEIL =     "ACCUEIL",
    APPOINTMENTS =   "RENDEZ-VOUS",
    VEHICLES =  "VÉHICULES",
    PROFILE = 'Profil',
    SETTINGS =   "Préférences",
    SUPPORT =   "Aide",
    LOGOUT =    "Se déconnecter"
};